import { useState, useEffect } from "react";
import { withTranslation } from 'react-i18next';
import { 
  useSelector, 
  useDispatch,
} from "react-redux";
import { useQuery, useMutation } from "react-query";

import logger from "../../utility/logger.mjs";
import logout from "../../utility/logout.js";
import {
  phonenumberSortFunc,
  userTypes
} from '../../utility/userConfig.mjs'

import { 
  checkPhonenumbers,
  dropAllPhonenumbers,
  updatePhonenumbers
} from "../../server/serverOperation.js";
import { 
  selectCurrentDeviceId, 
  selectUserPhoneId,
  currentDeviceSet
} from '../../data/uiSlice.js';
import { 
  selectCurrentPhonenumbers, 
  phonenumbersSet,
  selectPrivileges,
} from '../../data/devicesSlice.js';
import PhonenumberTable from "./PhonenumberTable.js";
import { RawDialog } from "../InfoDialog.js";
import PhonenumberInput from "./PhonenumberInput2.js";
import { ButtonWide } from "../Button.js";
import useConfirm from "../ConfirmDialog.js";
import { AlertError, AlertWarning } from "../Alert.js";

import { 
  Box,
  Link,
  Typography
} from '@mui/material';

import { 
  USER_COUNT_MAX, 
  ALARM_CALL_COUNT_MAX 
} from "../../utility/userConfig.mjs";

const TELEGRAM_BOT_URL = process.env.REACT_APP_TELEGRAM_BOT_URL;

const PurePhonenumberEditDialog = ({t}) => {
  const dispatch = useDispatch();
  const deviceId = useSelector(selectCurrentDeviceId);
  const userPhoneId = useSelector(selectUserPhoneId);
  const privileges = useSelector(selectPrivileges);
  const currentPhonenumbers = useSelector(selectCurrentPhonenumbers)
  const [phonenumbers, setPhonenumbers] = useState(currentPhonenumbers.map( number => ({ ...number, checked: true })));
  const noSuperuserSelected = !phonenumbers.some( n => (n.userType === "superadmin" && !n.deleted))
  const [numberCount, setNumberCount] = useState(phonenumbers);
  const initialNewNumber = {
    phonenumber: "", 
    country: "", 
    userType: userTypes[2], 
    alarmCall: 0, 
    telegramId: false, 
    checked: false,
    deleted: false,
  };
  const [newNumber, setNewNumber] = useState(initialNewNumber);
  const confirm = useConfirm();
  
  useEffect(() => {
    setNumberCount(phonenumbers.filter( n => !n.deleted).length);
  },[phonenumbers]);

  useEffect(() => {
    // find an index for a number that is not the supeadmin or the current user
    // and mark the number deleted if there are too many numbers 
    if(numberCount > USER_COUNT_MAX){
      const i = phonenumbers.findIndex( n => n.userType !== "superadmin" && n.id !== "userPhoneId");
      setPhonenumbers( prev => [...prev.slice(0,i), {...prev[i], deleted: true, alarmCall: false}, ...prev.slice(i + 1)])
    }
  },[numberCount])
  
  const counts = () => {
    return (
      <Box style={{margin: "auto", textAlign: "center", width:300}}>
        <em>{t("numbers")}</em> {numberCount}/{USER_COUNT_MAX} | <em>{t("call-numbers")}</em> {phonenumbers.filter( n => n.alarmCall).map( n => n.id).length}/{ALARM_CALL_COUNT_MAX }
      </Box>
    )
  } 
  
  const infoText = () => {
    return (
      <p>{t("phonenumbers-info")}<Link href={TELEGRAM_BOT_URL + '?start'} target="_blank">{t("with-telegram-bot")}</Link>.</p>
    )
  }

  const handleAddNumberClick = () => {
    if(phonenumbers.find( n => n.phonenumber === newNumber.phonenumber)) {
      return
    }
    setPhonenumbers(prev => [...prev, newNumber].sort(phonenumberSortFunc))
    setNewNumber(initialNewNumber);
  }

  const { refetch:recheckPhonenumbers, isFetching:numberCheckIsLoading} = useQuery(
    ("numberCheck"),
    async () => {
      const numbers = phonenumbers.map( n => n.phonenumber );
      return checkPhonenumbers(numbers);
    },
    { enabled: false }
  );

  const checkNumbers = async () => {
    const numberCheckData = await recheckPhonenumbers();
    if(numberCheckData?.data?.ok){
      setPhonenumbers(
          phonenumbers.map( n => ({...n, checked: true, telegramId: numberCheckData.data.data[n.phonenumber]}) )
        );
    }
  }

  const { mutate:mutateUpdatePhonenumbers, isLoading:updatePhonenumbersIsLoading } = useMutation(
    updatePhonenumbers, // parameter {deviceId, phonenumbers}
    {
      onSuccess: (data) => {
        dispatch(phonenumbersSet({deviceId, phonenumbers: data.phonenumbers}));
      },
      onError: (error) => {
        logger.error("sendPhonenumber", error);
      }
    }
  );

  const { mutate:executeDropAllNumbers } = useMutation(
    dropAllPhonenumbers, // parameter {deviceId}
    {
      onError: (error) => {
        logger.error("dropAllNumbers", error);
      }
    }
  );

  const handleSaveClick = async (dialogCloseFunc) => {
    var alert = false;
    const noSuperadmin = !phonenumbers.some( n => n.userType === "superadmin" && !n.deleted && !!n.telegramId);
    const deletingOneSelf = phonenumbers.find( n => n.id === userPhoneId).deleted;
    const downgradingOneSelf = userTypes.indexOf(privileges) < userTypes.indexOf(phonenumbers.find( n => n.id === userPhoneId).userType);
    if(noSuperadmin){
      alert = <AlertError text={"no-superadmin-confirm-warning"} title={"warning-deleting-superadmin-title"} />
    }
    else if(deletingOneSelf) {
      alert = <AlertError text={"warning-deleting-oneself"} title={"warning-deleting-oneself-title"} />
    }
    // downgrading one self
    else if(downgradingOneSelf) {
      alert = <AlertError text={"warning-losing-admin-privileges"} title={"warning-losing-admin-privileges-title"} />
    }
    
    if(!alert){ // everything ok!
      await handleSaveOk(dialogCloseFunc);
      return;
    }

    const confirmationOk = await confirm({
      title: "warning",
      children: alert,
      okButtonText: "ok",
      cancelButtonText: "cancel"
    });

    if(confirmationOk){
      if(noSuperadmin){
        executeDropAllNumbers(deviceId);
        logout();
        return;
      }
      if(deletingOneSelf){
        await handleSaveOk(dialogCloseFunc);
        logout();
        return;
      }
      if(downgradingOneSelf){
        await handleSaveOk(dialogCloseFunc);
        return;
        //dispatch(currentDeviceSet(8));
      }
    } 
  }

  const handleSaveOk = async (dialogCloseFunc) => {
    await checkNumbers();
    // ignore deleted new numbers 
    const phonenumberToUpdate = phonenumbers.filter( n => !n.deleted || n.id );
    mutateUpdatePhonenumbers({deviceId, phonenumbers:phonenumberToUpdate});
    dialogCloseFunc();
    clearDialog();
  }

  const clearDialog = () => {
    setPhonenumbers(currentPhonenumbers.map( number => ({ ...number, checked: true })));
  }

  return ( 
    <Box sx={{textAlign: "left"}} >
      <RawDialog 
        data={{
          title: "phonenumbers", 
          openButtonText: "edit-numbers",
          openButtonType: "ButtonSmall",
          okButtonText: "save",
          okButtonFunc: handleSaveClick,
          okButtonIsLoading: numberCheckIsLoading || updatePhonenumbersIsLoading,
          //okButtonDisabled: !somethingChanged,
          cancelButtonText: "cancel",
          //cancelButtonFunc: handleSaveClick,
          cancelButtonIsLoading: numberCheckIsLoading || updatePhonenumbersIsLoading,
          extraButtonText: "clear",
          extraButtonFunc: clearDialog,
          extraButtonIsLoading: numberCheckIsLoading || updatePhonenumbersIsLoading,

        }}
        openButtonSX={{mb:0}}
        >
          { infoText() }
          { counts() }
          <PhonenumberTable 
            phonenumbers={phonenumbers} 
            setPhonenumbers={setPhonenumbers} 
            />
          <Box sx={{display: "flex", flexWrap: "wrap", alignItems: "flex-start"}}>
            <Box sx={{border: "1px LightGray solid", borderColor:"secondary.main", borderRadius: 1, padding: "0.5em 1em", width:"265px", margin: "0 0.5em 0.5em 0"}}>
              <Typography variant="h2" style={{textAlign: "left",marginTop: "0em", marginBottom: "0.5em"}}>{t("new-number")}</Typography>
              <PhonenumberInput 
                number={newNumber} 
                setNewNumber={setNewNumber} 
                disabled={ numberCount >= USER_COUNT_MAX }
                noSuperuserSelected={noSuperuserSelected}
                />
              <ButtonWide 
                onClick={handleAddNumberClick} 
                width="20%" 
                text={t("add")} 
                margin="9px 5px"
                disabled={newNumber.phonenumber.length === 0 || numberCount >= USER_COUNT_MAX }
                borders
              />
            </Box>
            <Box sx={{border: "1px solid", borderColor:"secondary.main", borderRadius: 1, padding: "0.5em 1em", width:"265px", margin: "0 0 0.5em 0"}}>
            <Typography variant="h2" style={{textAlign: "left",marginTop: "0em", marginBottom: "0.5em"}}>{t("numbers-check-header")}</Typography>
              <Box sx={{margin: "0 auto",width:"50%" }}>
                <ButtonWide 
                  onClick={checkNumbers} 
                  width="100%"
                  margin="5 0" 
                  text={ !phonenumbers.some( n => !(n.checked) ) ? "number-check-ok" : "check-numbers"} 
                  disabled={ !phonenumbers.some( n => !(n.checked)) }
                  isLoading={numberCheckIsLoading}
                  borders
                />              
              </Box>
            </Box>
          </Box>
      </RawDialog>
    </Box>
  )
}
const PhonenumberEditDialog = withTranslation()(PurePhonenumberEditDialog);
export default PhonenumberEditDialog;