import { withTranslation } from 'react-i18next';

import PowerOffOutlinedIcon from '@mui/icons-material/PowerOffOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import HumidityIcon from './HumidityIcon';
import MovementIcon from './MovementIcon';
import PressureIcon from './PressureIcon';
import SPLIcon from './SPLIcon';
import TemperatureIcon from './TemperatureIcon';
import LuminanceIcon from './LuminanceIcon';
import Co2Icon from '@mui/icons-material/Co2';
// Using a generic Co2 icon (with text "co2") for clarity
// import Co2Icon from '../Icons/Co2Icon';

import { Tooltip } from '@mui/material';

const IconCompnent = {
  "psuMonitorAD-off": PowerOffOutlinedIcon,
  "psuMonitorAD-on": PowerOutlinedIcon,
  "temperature": TemperatureIcon,
  "pressure": PressureIcon,
  "humidity": HumidityIcon,
  "leakSensorADValue": WaterDropIcon,
  "PIR": MovementIcon,
  "SPL": SPLIcon,
  "CO2": Co2Icon,
  "luminance": LuminanceIcon,
  "VOC": HumidityIcon, // VOC don't have own icon --> fix when it has
}

// A tooltip for the icon telling the name of the type.
const PureTooltipWrapper = ({t, children, tooltipTitle, disabled, type}) => {
  if(disabled){
    return <>{children}</>;
  }
  return <Tooltip title={tooltipTitle ?? t(type)}>{children}</Tooltip>;
}
const TooltipWrapper = withTranslation()(PureTooltipWrapper);


// type: type of the alarm, str
export const AlarmIcon = ({type, sx, color="warning", tooltipTitle, tooltipDisabled}) => {
  if(type == null){
    return false;
  }
  const Icon = IconCompnent[type] || WarningRoundedIcon;
  return (
    <TooltipWrapper type={type} tooltipTitle={tooltipTitle} disabled={tooltipDisabled}>
      <Icon sx={sx} color={color} />
    </TooltipWrapper>
  );
}