import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 6.35 6.35"
    stroke="currentColor"
  >
    <g 
      fill="none" 
      strokeLinecap="round" 
      strokeLinejoin="round"
      >
      <path
        strokeWidth={0.415}
        d="M2.665 2.114v1.67h1.052"
      />
      <path
        strokeWidth={0.475}
        d="M3.151 5.529s-2.07-.18-2.07-3.586C2.67 2.23 3.169.767 3.169.767s.497 1.461 2.087 1.174c0 .642-.073 1.169-.192 1.602M3.186 5.527s.98-.085 1.592-1.248h-2.61"
      />
    </g>
  </svg>
);




