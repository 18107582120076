import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    stroke="currentColor"
    viewBox="0 0 6.35 6.35"
  >
    <defs>
      <clipPath id="a">
        <path fillRule="evenodd" d="M642 317h57v55h-57z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="b">
        <path fillRule="evenodd" d="M646 233h42v46h-42z" clipRule="evenodd" />
      </clipPath>
    </defs>
    <g
      style={{
        display: "inline",
      }}
    >
      <circle
        cx={3.178}
        cy={3.178}
        r={0.677}
        style={{
          fill: "none",
          fillOpacity: 1,
          strokeWidth: 0.277,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke fill markers",
        }}
      />
      <path
        d="M3.178.237v1.888m0 2.088V6.11M6.115 3.174H4.227m-2.087 0H.242M2.734 2.226l-.67-1.162M3.639 2.226l.67-1.162M2.734 4.119l-.67 1.162M3.639 4.119l.67 1.162M4.143 2.749l1.162-.671M4.143 3.653l1.162.67M2.174 2.749l-1.162-.671M2.174 3.653l-1.162.67"
        style={{
          fill: "none",
          fillOpacity: 1,
          strokeWidth: 0.277,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke fill markers",
        }}
      />
    </g>
  </svg>
);