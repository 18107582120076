import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { toast } from 'react-toastify';

import logger from '../utility/logger.mjs';
import useParamSelector from '../data/useParamSelector.js'
import { 
  currentSettingsSet,
  selectComponentValues,
  selectCurrentComponentDesc,
  deviceSettingsRefreshed,
} from '../data/devicesSlice.js';
import { 
  selectHourCycle, 
  selectUserPhoneId,
  hourCycleSet,
  unitSystemSet,
  selectUnitSystem,
  selectCurrentDeviceId
} from "../data/uiSlice.js";
import { updateHourCycle, updateUnitSystem } from "../server/serverOperation.js";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { withTranslation } from 'react-i18next';

function PureRadioButtons({ t, block }) {
  const dispatch = useDispatch();
  const disabled = block.disabled ?? false;
  const componentId = block.id;
  const data = useParamSelector(selectCurrentComponentDesc, componentId); //useSelector(state => selectCurrentComponentDesc(state, componentId))
  const desc = data.description;
  const orientation = desc?.ORIENTATION;
  //const position = desc?.POSITION;
  // LEBEL is an array of label/value pairs: [[label1, value1],[label2, value2],...]
  const labels = desc?.LABELS.map( l => l[0]);
  const buttonValues = desc?.LABELS.map( l => l[1]);
  const title = desc?.TITLE;
  const valInd = data.JSONValInd;
  //const jsonkey =  data.jsonkeys.flat(1);
  const currentValue = useParamSelector(selectComponentValues, componentId)[0]; 
  // find the button value that is the last <= the current value sent by the device
  const initialButtonValue = buttonValues.toSorted().filter( value => value <= currentValue ).slice(-1)[0] ?? 0;
  const [buttonValue, setButtonValue] = useState(initialButtonValue)

  const handleChange = (event, newValue) => {
    setButtonValue(parseInt(newValue));
    dispatch(currentSettingsSet({
      values: [parseInt(newValue)],
      jsonkeys: data.jsonkeys,
      valInd
    }));
  };
  
  return (
    <FormControl style={{textAlign: "left", marginLeft: "1em"}}>
      <FormLabel>{t(title)}</FormLabel>
      <RadioGroup
        row={orientation !== "vertical"}
        name="radio-buttons-group"
        value={buttonValue}
        onChange={handleChange}
      >
        {labels.map ( (label, i) =>
          <FormControlLabel 
            key={label} 
            value={buttonValues[i]} 
            control={<Radio disabled={disabled}/>} 
            label={`${t(label)}-abbreviation`} 
            />
        )}
      </RadioGroup>
    </FormControl>
  );
};
const RadioButtons = withTranslation()(PureRadioButtons);


let menuButton = {
  hourCycle: [
    {label: "24h", value: "h23"},
    {label: "12h", value: "h12"},
    ],
  unitSystem: [
    {label: "SI", value: "SI"},
    {label: "SAE", value: "SAE"},
    ],
}

function PureRadioButtonsForMenu({t, type}) {
  const dispatch = useDispatch();
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const userPhoneId = useSelector(selectUserPhoneId);
  const hourCycle = useSelector(selectHourCycle);
  const unitSystem = useSelector(selectUnitSystem);
  const buttonValue = type === "hourCycle" ? hourCycle : unitSystem;
  const handleChange = (event, newValue) => {
    if(type === "hourCycle"){
      dispatch(hourCycleSet(newValue));
      mutateHourCycle({userPhoneId, hourCycle:newValue});
      dispatch(deviceSettingsRefreshed(currentDeviceId))
    }
    if(type === "unitSystem"){
      dispatch(unitSystemSet(newValue));
      mutateUnitSystem({userPhoneId, unitSystem:newValue});
      dispatch(deviceSettingsRefreshed(currentDeviceId))
    }
  };

  const { mutate:mutateHourCycle, isLoading:hourCycleMutateIsLoading } = useMutation(
    updateHourCycle, // userPhoneId, hourCycle}
    {
      // onSuccess: async (data) => {
      //   console.log(data);
      // },
      onError: (error) => {
        toast.error(t("server-error"), {
          toastId: "server-error-hour-cycle"
        });
        console.log(error);
      }
    }
  );

  const { mutate:mutateUnitSystem, isLoading:unitSystemMutateIsLoading } = useMutation(
    updateUnitSystem, // userPhoneId, newUnitSystem}
    {
      // onSuccess: async (data) => {
      //   console.log(data);
      // },
      onError: (error) => {
        toast.error(t("server-error"), {
          toastId: "server-error-unit-system"
        });
        console.log(error);
      }
    }
  );

  const isLoading = hourCycleMutateIsLoading || unitSystemMutateIsLoading;

  return (
    <FormControl style={{textAlign: "left", marginLeft: "1em"}}>
      <FormLabel>{t(type === "hourCycle" ? "hour-cycle" : "unit-system")}</FormLabel>
      <RadioGroup
        row
        name="row-radio-buttons-group"
        value={buttonValue}
        onChange={handleChange}
      >
        {menuButton[type].map( button => 
          <FormControlLabel key={button.label} value={button.value} control={<Radio disabled={isLoading} />} label={t(button.label)} />
        )}
      </RadioGroup>
    </FormControl>
  );
};
const RadioButtonsForMenu = withTranslation()(PureRadioButtonsForMenu);

export {
  RadioButtons,
  RadioButtonsForMenu
};