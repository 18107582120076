import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 6.35 6.35"
    stroke="currentColor"
    overflow="hidden"
    fill="none"
  >
    <defs>
      <clipPath id="a">
        <path fillRule="evenodd" d="M642 317h57v55h-57z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="b">
        <path fillRule="evenodd" d="M646 233h42v46h-42z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="c">
        <path fillRule="evenodd" d="M637 138h60v62h-60z" clipRule="evenodd" />
      </clipPath>
    </defs>
    <g
      style={{
        display: "inline",
      }}
    >
      <g
        strokeMiterlimit={8}
        clipPath="url(#c)"
        style={{
          overflow: "hidden",
        }}
        transform="matrix(.10269 0 0 .10269 -65.357 -14.213)"
      >
        <path
          fill="none"
          strokeWidth={5}
          d="M640.5 169.5c0-15.464 12.088-28 27-28s27 12.536 27 28-12.088 28-27 28-27-12.536-27-28z"
        />
        <path
          fillRule="evenodd"
          d="M661.5 169.5a6 6 0 1 1 12 0 6 6 0 0 1-12 0z"
        />
        <path
          fill="none"
          strokeWidth={4}
          d="M667 156c6.627 0 12 5.149 12 11.5"
        />
        <path
          fill="none"
          strokeWidth={4}
          d="M656 168.5c0-6.904 5.149-12.5 11.5-12.5M656 180.459l7.12-7.46"
        />
      </g>
    </g>
  </svg>
);




