import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { selectAllDevices } from '../../data/devicesSlice';
import { currentDeviceSet, selectCurrentDeviceId } from '../../data/uiSlice';
import { useMemo } from 'react';

const DeviceSelect = ({t}) => {
  const dispath = useDispatch()
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const allDevices = useSelector(selectAllDevices);
  const activeDevices = useSelector(selectAllDevices).filter(device => device.inUse)
    .sort((a,b) => a.projectId - b.projectId);
  const projects = useSelector(state => state.locations.project);
  const options = useMemo(() => activeDevices.map(device => ({
    id: device.id,
    name: device.name,
    project: projects.find(project => project.id === device.projectId).name,
  })),[allDevices]);
  const currentOption = useMemo(() => options.find( option => option.id === currentDeviceId), [allDevices])

  const handleValueChange = (_,option) => {
    if(option != null){
      dispath(currentDeviceSet(option.id));
    }
  }

  return (
    <Autocomplete
      value={currentOption}
      onChange={handleValueChange}
      options={options}
      sx={{ width: 300 }}
      groupBy={(option) => option.project}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      renderInput={(params) => <TextField {...params} label={t('device')} size="small" />}
    />
  );
}
export default withTranslation()(DeviceSelect);