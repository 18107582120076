import { useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';

import { getDeviceTypeAlerts } from "../../utility/deviceConfig.js";
import { selectDeviceById } from "../../data/devicesSlice.js";
import { selectAlarmsByDeviceId } from "../../data/alarmsSlice.js";
import { AlarmDialog } from "./AlarmDialog.js";
import { AlarmIcon } from "../Icons/AlarmIcon.js";

const PureDeviceAlarms = ({t, id, openButtonType="alarmIcon"}) => {
  const alarms = useSelector(selectAlarmsByDeviceId(id));
  const deviceAlarmTypes = getDeviceTypeAlerts(useSelector(selectDeviceById(id)).type);
  console.log("deviceAlarmTypes",deviceAlarmTypes,alarms)
  if(alarms?.lenght === 0){
    return false
  }

  return (
    <>
      {deviceAlarmTypes.map( alarmType => {
        const alarm = alarms.find( a => a.type == alarmType);
        if(alarm == null || alarm.alarmAckedByUserPhone || alarm.alarmAckedByUserTG ){
          return (
            <AlarmIcon type={alarmType === "psuMonitorAD-off" ? "psuMonitorAD-on" : alarmType} sx={{mb:-0.7, mx:0.7}} color="disabled" />
          )
        }
        return <AlarmDialog alarm={alarm} openButtonType={openButtonType} />
      })}
    </>
  )
}

const DeviceAlarms = withTranslation()(PureDeviceAlarms);
export { 
  DeviceAlarms 
};