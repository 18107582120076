import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { currentPageSet } from '../data/uiSlice.js';
import { InfluxCharts } from "../components/InfluxChart.js";

import DeviceHeader from '../components/Devices/DeviceHeader.js';

function SubPageData({t}) {
  useDispatch()(currentPageSet("DATA"));

  return (
    <>
      <DeviceHeader />
      <InfluxCharts />
    </>
  );

}

export default withTranslation()(SubPageData);
