import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import { selectAllLocationTypes } from "../../data/locationsSlice";
import { LocationTypeIcon } from "../Icons/LocationTypeIcon";

import { 
  Box,
  MenuItem, 
  Select,
  Stack,
} from "@mui/material";

const PureLocationTypeSelect = ({t, onChange, type, disabled, tooltipDisabled,sx}) => {
  const locationTypes = useSelector(selectAllLocationTypes) ?? [];

  return (
    <Select
      sx={sx}
      variant="standard"
      onChange={onChange} 
      value={type}
      disabled={disabled}
    >
      {locationTypes
        .map( type =>
          <MenuItem key={type} value={type}>
            <Stack direction="row" spacing={1} sx={{alignItems: "center",}}>
              <LocationTypeIcon type={type} tooltipDisabled/>
              <Box>{t(type)}</Box>
            </Stack>
          </MenuItem>
        )
      }
    </Select>
  )
}

const LocationTypeSelect = withTranslation()(PureLocationTypeSelect);
export {
  LocationTypeSelect
};