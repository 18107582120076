import { useState } from "react";

import { 
  IconButton, 
  Stack
} from "@mui/material";
import { 
  ExpandMore 
} from "@mui/icons-material";

// The function is modified from: https://mui.com/x/react-data-grid/master-detail/
const DetailPanelToggle = ({isExpanded}) => {
  return (
    <IconButton
      size="small"
      tabIndex={-1}
      aria-label={isExpanded ? 'Close' : 'Open'}
    >
      <ExpandMore
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
}

// Function for formating the name column of the devices table.
export default ({id,row,api}) => {
  const [detailPanelIsExpanded, setDetailPanelIsExpanded] = useState(api.getExpandedDetailPanels().includes(id));

  const handleNameClick = (event) => {
    setDetailPanelIsExpanded(!detailPanelIsExpanded)
    api.toggleDetailPanel(id);
    event.stopPropagation();
  }

  return (
    <Stack direction="row" onClick={handleNameClick} >
      {row.name}
      <DetailPanelToggle isExpanded={detailPanelIsExpanded} />
    </Stack>
  )
}