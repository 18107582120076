import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 6.35 6.35"
    stroke="currentColor"
  >
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M-3.798 9.678a2.083 2.083 0 0 1-2.083 2.083 2.083 2.083 0 0 1-2.082-2.083 2.083 2.083 0 0 1 2.082-2.082 2.083 2.083 0 0 1 2.083 2.082Z"
        style={{
          fill: "currentColor",
          strokeWidth: 1.5,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          paintOrder: "stroke fill markers",
        }}
        transform="matrix(.22144 0 0 .22144 3.877 .954)"
      />
      <path
        d="m-7.993 9.142 2.103-5.067 2.131 5.136"
        style={{
          fill: "currentColor",
          fillOpacity: 1,
          strokeWidth: 1.31839,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeDasharray: "none",
          paintOrder: "stroke fill markers",
        }}
        transform="matrix(.22144 0 0 .22144 3.877 .954)"
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M-3.798 9.678a2.083 2.083 0 0 1-2.083 2.083 2.083 2.083 0 0 1-2.082-2.083 2.083 2.083 0 0 1 2.082-2.082 2.083 2.083 0 0 1 2.083 2.082Z"
        style={{
          strokeWidth: 1.5,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          paintOrder: "stroke fill markers",
        }}
        transform="translate(4.226 3.09) scale(.1833)"
      />
      <path
        d="m-7.993 9.142 2.103-5.067 2.131 5.136"
        style={{
          fill: "currentColor",
          fillOpacity: 1,
          strokeWidth: 1.31839,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeDasharray: "none",
          paintOrder: "stroke fill markers",
        }}
        transform="translate(4.226 3.09) scale(.1833)"
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M-3.798 9.678a2.083 2.083 0 0 1-2.083 2.083 2.083 2.083 0 0 1-2.082-2.083 2.083 2.083 0 0 1 2.082-2.082 2.083 2.083 0 0 1 2.083 2.082Z"
        style={{
          fill: "currentColor",
          strokeWidth: 1.5,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          paintOrder: "stroke fill markers",
        }}
        transform="matrix(.14161 0 0 .14161 4.581 1.916)"
      />
      <path
        d="m-7.993 9.142 2.103-5.067 2.131 5.136"
        style={{
          fill: "currentColor",
          fillOpacity: 1,
          strokeWidth: 1.31839,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeDasharray: "none",
          paintOrder: "stroke fill markers",
        }}
        transform="matrix(.14161 0 0 .14161 4.581 1.916)"
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M-3.798 9.678a2.083 2.083 0 0 1-2.083 2.083 2.083 2.083 0 0 1-2.082-2.083 2.083 2.083 0 0 1 2.082-2.082 2.083 2.083 0 0 1 2.083 2.082Z"
        style={{
          fill: "currentColor",
          strokeWidth: 1.5,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          paintOrder: "stroke fill markers",
        }}
        transform="matrix(.07388 0 0 .07388 4.44 3.617)"
      />
      <path
        d="m-7.993 9.142 2.103-5.067 2.131 5.136"
        style={{
          fill: "currentColor",
          fillOpacity: 1,
          strokeWidth: 1.31839,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeDasharray: "none",
          paintOrder: "stroke fill markers",
        }}
        transform="matrix(.07388 0 0 .07388 4.44 3.617)"
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M-3.798 9.678a2.083 2.083 0 0 1-2.083 2.083 2.083 2.083 0 0 1-2.082-2.083 2.083 2.083 0 0 1 2.082-2.082 2.083 2.083 0 0 1 2.083 2.082Z"
        style={{
          fill: "currentColor",
          strokeWidth: 1.5,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          paintOrder: "stroke fill markers",
        }}
        transform="matrix(.09018 0 0 .09018 3.818 1.017)"
      />
      <path
        d="m-7.993 9.142 2.103-5.067 2.131 5.136"
        style={{
          fill: "currentColor",
          fillOpacity: 1,
          strokeWidth: 1.31839,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeDasharray: "none",
          paintOrder: "stroke fill markers",
        }}
        transform="matrix(.09018 0 0 .09018 3.818 1.017)"
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="M-3.798 9.678a2.083 2.083 0 0 1-2.083 2.083 2.083 2.083 0 0 1-2.082-2.083 2.083 2.083 0 0 1 2.082-2.082 2.083 2.083 0 0 1 2.083 2.082Z"
        style={{
          strokeWidth: 1.5,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          paintOrder: "stroke fill markers",
        }}
        transform="matrix(.09018 0 0 .09018 2.71 4.022)"
      />
      <path
        d="m-7.993 9.142 2.103-5.067 2.131 5.136"
        style={{
          fill: "currentColor",
          fillOpacity: 1,
          strokeWidth: 1.31839,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeDasharray: "none",
          paintOrder: "stroke fill markers",
        }}
        transform="matrix(.09018 0 0 .09018 2.71 4.022)"
      />
    </g>
    <path
      d="M14.455 4.931c.093.062.21.146.556.162.461.021.545.183.275.232-.642.114.186.175 1.09.185.247.003.359.039.437.04.92.008 2.295-.13 2.124-.232-.038-.023-.378-.043-.423-.06-.3-.349 1.339-.225 1.287-.44-.067-.285-1.626-.011-2.44-.302-.399-.143-.77.024-.842.089-.105.093-.472.046-.623.065-.043.011-.226.01-.243.011-.543.032-1.59-.007-1.198.25z"
      style={{
        fill: "currentColor",
        fillOpacity: 1,
        strokeWidth: 0.0411195,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeDasharray: "none",
        paintOrder: "stroke fill markers",
      }}
      transform="translate(-13.791)"
    />
    <path
      d="M16.896 6.118s-.426-.036-.928-.369m-1.1-1.338c-.287-.626-.487-1.482-.487-2.649 1.932.349 2.536-1.43 2.536-1.43s.604 1.776 2.536 1.428c0 1.13-.187 1.968-.46 2.589M17.762 5.76c-.305.295-.824.355-.824.355"
      style={{
        display: "inline",
        fill: "none",
        strokeWidth: 0.377,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeDasharray: "none",
        paintOrder: "stroke fill markers",
      }}
      transform="translate(-13.791)"
    />
  </svg>
);




