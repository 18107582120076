import { useState } from "react";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import { selectCurrentDeviceId } from "../../data/uiSlice";
import DeviceSelect from "./DeviceSelect";
import DeviceDetails from "./DeviceDetails";

import { 
  Collapse,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { 
  ExpandLess, 
  ExpandMore,
} from "@mui/icons-material";

const DeviceHeader = ({t}) => {
  const currentDeviceId = useSelector(selectCurrentDeviceId);
  const [showDeviceDetails, setShowDeviceDetails] = useState(false);
  return (
    <>
    <Stack 
      direction="column" 
      sx={{

      }}
    >
      <DeviceSelect />
      
      <Stack 
        direction="row"
        sx={{
          alignItems: "center",
        }}
      >
        <Typography 
          variant="h2"
          onClick={() => setShowDeviceDetails(!showDeviceDetails)}
          sx={{m:0}}
        >
          {t("device-details")}
        </Typography>
        <IconButton onClick={() => setShowDeviceDetails(!showDeviceDetails)}>
          {showDeviceDetails ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </Stack>
    </Stack>
    <Collapse in={showDeviceDetails} timeout="auto" unmountOnExit>
      <DeviceDetails deviceId={currentDeviceId} showDeviceName />
    </Collapse>
    <Divider sx={{ mt: 2, mb: 2, clear:"both" }}/>
    </>
  );
}
export default withTranslation()(DeviceHeader);