export const DEVICE_NAME_LENGTH_MAX = 99;

export const getDeviceTypeAlerts = (typeName) => {
  switch (typeName) {
    case "MultiGuard THVCLPS":
      return ["humidity", "temperature", "SPL", "PIR", "luminance", "CO2", "VOC"];

    case "MG Olosuhdevahti":
     return ["temperature", "humidity", "SPL", "PIR"];

    case "LeakGuard":
      return ["leakSensorADValue"];

    case "PressureGuard":
    case "PressureGuard rev2":
      return ["temperature", "pressure", "psuMonitorAD-off"];

    default:
      return [];
  }
}

export const LEAKGUARD_DRY_LIMIT = 1000;
export const LEAKGUARD_WET_LIMIT = 20;

export const prettifyLeakSensorADValue = (value) => {
  if(value > LEAKGUARD_DRY_LIMIT) return 'dry';
  if(value < LEAKGUARD_WET_LIMIT) return 'wet';
  return 'normal'
}