import { round } from '../utility/function.mjs' 

// alar limit types of cencors (SOURCE: data specification rev14)
const LIMIT_TYPE = {
  off: 0,
  absolute: 1, // "fixed limit"
  refFixed: 2, // "reference mixed limit" 
  relative: 3, // "referennce relative limits"
}

const RESULUTION = 500 // data points in aggregateWindow
const moreThanZero = (int) => {
  return int > 0 ? int : 1;
}
const calulateWindow = (hours) => {
  return moreThanZero(Math.round((hours * 3600)/RESULUTION)) + "s"
}


const DEFAULT_REFERENCE_TIME = "-1d";
const timeRanges = (referenceTime = DEFAULT_REFERENCE_TIME, activationTime = DEFAULT_REFERENCE_TIME) => {
  return [
    { // i: 0
      name: 'three-hours',
      range: {
        start: '-3h',
        end: null
      },
      aggregateWindow: calulateWindow(3)
    },
    { // i: 1
      name: 'one-day',
      range: {
        start: '-1d',
        end: null
      },
      aggregateWindow: calulateWindow(24)
    },
    { // i: 2
      name: 'one-week',
      range: {
        start: '-7d',
        end: null
      },
      aggregateWindow: calulateWindow(7 * 24)
    },
    { // i: 3
      name: 'one-month',
      range: {
        start: '-1mo',
        end: null
      },
      aggregateWindow: calulateWindow(30 * 24)
    },
    { // i: 4
      name: 'six-months',
      range: {
        start: '-6mo',
        end: null
      },
      aggregateWindow: calulateWindow(180 * 24)
    },    
    { // i: 5
      name: 'one-year',
      range: {
        start: '-1y',
        end: null
      },
      aggregateWindow: calulateWindow(365 * 24)
    },
    { // i: 6
      name: 'from-reference-time',
      range: {
        // if reference time is older than one month, start 30 days ago
        start: new Date().getTime() - new Date(referenceTime).getTime() > 2629800000 ? "-30d" : referenceTime,
        end: null
      },
      aggregateWindow: 
        referenceTime === '-1d' 
          ? calulateWindow(24)
          : moreThanZero(Math.round(((new Date().getTime()/1000) - (new Date(referenceTime).getTime()/1000))/RESULUTION)) + 's'
    },
    { // i: 7
      name: 'from-activation-time',
      range: {
        start: activationTime,
        end: null
      },
      aggregateWindow: 
        activationTime === '-1d' 
          ? calulateWindow(24)
          : moreThanZero(Math.round(((new Date().getTime()/1000) - (new Date(activationTime).getTime()/1000))/RESULUTION)) + 's'
    },
  ];
}

// TODO aggregateWindowin olisi parempi mennä saadun datan mukaan, ei haun mukaan
const customTimeRange = (start="", end="") => {
  return {
    name: 'custom-timerange',
    range: {
      start,
      end
    },
    aggregateWindow: moreThanZero(Math.round(((new Date(end).getTime()/1000) - (new Date(start).getTime()/1000))/RESULUTION)) + "s"
  }
}

const strokeColor = {
  temperature: "#F47174",
  pressure: "#6464ff",
};

const DEFAULT_UNIT = "SI"
const DECIMALS = 2;
const unit = {
  SI: {
    general: {
      symbol: "",
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    minutes: {
      symbol: "min",
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    temperature: {
      symbol: '°C',
      conv: (x, _) => x,
      convBack: (x, decimals = DECIMALS) => round(x, decimals),
    },
    pressure: {
      symbol: 'bar',
      conv: (x, _) => x,
      convBack: (x, decimals = DECIMALS) => round(x, decimals),
    },
    // temperatureAverage: {
    //   symbol: '°C',
    //   conv: (x, _) => x,
    //   convBack: (x, decimals = DECIMALS) => round(x, decimals),
    // },
    // pressureAverage: {
    //   symbol: 'bar',
    //   conv: (x, _) => x,
    //   convBack: (x, decimals = DECIMALS) => round(x, decimals),
    // },
    batteryVoltage: {
      symbol: 'V',
      conv: (x, _) => x,
      convBack: (x, decimals = DECIMALS) => round(x, decimals),
    },
    humidity: {
      symbol: '%',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    PIR: {
      symbol: '',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    SPL: {
      symbol: 'dB',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    leakSensorADValue: {
      symbol: '',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
  },
  SAE: {
    general: {
      symbol: "",
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    minutes: {
      symbol: "min",
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    temperature: {
      symbol: '°F',
      conv: (x, decimals = DECIMALS) => round((x * 1.8) + 32, decimals),
      convBack: (x, decimals = DECIMALS) => round((x - 32) / 1.8, decimals)
    },
    pressure: {
      symbol: 'psi',
      conv: (x, decimals = DECIMALS) => round(x * 14.5038, decimals),
      convBack: (x, decimals = DECIMALS) => round(x / 14.5038, decimals),
    },
    batteryVoltage: {
      symbol: 'V',
      conv: (x, _) => x,
      convBack: (x, decimals = DECIMALS) => round(x, decimals),
    },
    humidity: {
      symbol: '%',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    PIR: {
      symbol: '',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    SPL: {
      symbol: 'dB',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
    leakSensorADValue: {
      symbol: '',
      conv: (x, _) => x,
      convBack: (x, _) => x,
    },
  },
};

const unitWithOutConvs = {
  SI: {
    temperature: {
      symbol: '°C',
    },
    pressure: {
      symbol: 'bar',
    },
    batteryVoltage: {
      symbol: 'V',
    },
  },
  SAE: {
    temperature: {
      symbol: '°F',
    },
    pressure: {
      symbol: 'psi',
    },
    batteryVoltage: {
      symbol: 'V',
    },
  },
};

const getInfluxDesc = (commTech) => {
  switch (commTech) {
    case "Sigfox":
      return {
        bucket:      "SigfoxData",
        idFieldName: "sensorId",
      }
    case "LTE-M":
    default:
      return {
        bucket:      "DeviceData",
        idFieldName: "Device.deviceUUID",
      }
  }
}

// const countryToUnitSystem = (country) => {
//   switch (country) {
//     case 'us':
//       return 'SAE'; 
//     default: // 'fi'
//       return 'SI';
//   };
// }

export {
  LIMIT_TYPE,
  strokeColor,
  // formatTime,
  timeRanges,
  //timeRangeWithoutFunction,
  customTimeRange,
 // customTimeRangeWithoutFunction,
  getInfluxDesc,
  unit,
  unitWithOutConvs,
  DEFAULT_UNIT,
  //countryToUnitSystem,
}