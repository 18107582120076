import React from 'react';
import { LicenseInfo } from '@mui/x-license';
import './index.css';
import ReactDOM from 'react-dom/client';
import { store } from './app/store.js';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from "react-query";
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";

import App from './App.js';
import TelegramLogger from './components/TelegramLogger.js'
import PageNotFound from './pages/PageNotFound.js';
import "./utility/i18n.js";
// import reportWebVitals from './reportWebVitals.js';

import queryClientConfig from './utility/queryClientConfig.js';

LicenseInfo.setLicenseKey('2053fe1838cae0fd6b0c42de277e6940Tz05NTM2MyxFPTE3NTQxMjYyNjQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient(queryClientConfig);
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          {/* <ConfirmDialogProvider> */}
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<App />} >
                  <Route path="/" element={<TelegramLogger />} />
                  <Route path="/urlCode/:urlParm" element={<TelegramLogger urlCodeLogin />} />
                  <Route path="/login/:urlParm" element={<TelegramLogger telegramLinkLogin />} />
                  <Route path="*" element={<PageNotFound />} />
                </Route>
              </Routes>
            </BrowserRouter>
        </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);

