import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 6.35 6.35"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M3.151 5.529s-2.07-.18-2.07-3.586C2.67 2.23 3.169.767 3.169.767s.497 1.461 2.087 1.174c0 .642-.073 1.169-.192 1.602M3.186 5.527s.98-.085 1.592-1.248h-2.61"
      style={{
        display: "inline",
        strokeWidth: 0.475043,
        strokeDasharray: "none",
        paintOrder: "stroke fill markers",
      }}
    />
    <g
      style={{
        display: "inline",
        strokeWidth: 2.64112,
        strokeDasharray: "none",
      }}
    >
      <path
        d="M-30.355-4.089v-9.079l5.163 5.164"
        style={{
          fillOpacity: 1,
          strokeWidth: 2.64112,
          strokeDasharray: "none",
          paintOrder: "stroke fill markers",
        }}
        transform="matrix(.15408 0 0 .15408 7.047 4.367)"
      />
      <path
        d="M-19.969-4.1v-9.079l-5.163 5.164"
        style={{
          display: "inline",
          
          fillOpacity: 1,
          strokeWidth: 2.64112,
          strokeDasharray: "none",
          paintOrder: "stroke fill markers",
        }}
        transform="matrix(.15408 0 0 .15408 7.047 4.367)"
      />
    </g>
  </svg>
);