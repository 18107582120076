import { useState } from "react";
import { withTranslation } from "react-i18next";

import { 
  IconButton,
  Tooltip,
} from "@mui/material";
import { 
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

const PureExpandAllToggle = ({t}) => {
  const [allExpanded, setAllExpanded] = useState(false);
  const apiRef = useGridApiContext();

  const handleExpandAllClick = () =>  {
      const rowIds = apiRef.current.getAllRowIds();
      rowIds.forEach( id => {
        if(apiRef.current.getRowNode(id).type === 'group'){
          apiRef.current.setRowChildrenExpansion(id, !allExpanded)
        }
      });
      setAllExpanded(!allExpanded)
  }

  return (
    <IconButton
      aria-label="unofold more"
      size="small"
      onClick={handleExpandAllClick}
    >
      <Tooltip title={allExpanded ? t('collapse-all') : t('expand-all')} >
        { allExpanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
      </Tooltip>
    </IconButton>
  )
}
const ExpandAllToggle = withTranslation()(PureExpandAllToggle);
export default ExpandAllToggle;