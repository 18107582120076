import { useDispatch } from "react-redux";

import { 
  currentDeviceSet,
  currentPageSet,
} from "../../data/uiSlice";

import {
  IconButton,
  Stack, 
} from "@mui/material";
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { ActivateDevicesDialog } from "./ActivateDevicesDialog";

export default ({deviceId, variant, hide}) => {
  const dispatch = useDispatch()
  const handleActionButtonClick = (targetPage) => {
    dispatch(currentDeviceSet(deviceId));
    dispatch(currentPageSet(targetPage));
  }

  if(hide){
    return false;
  }

  if(variant === "activateDevice"){
    return ([
      <ActivateDevicesDialog openButtonType={"plus"}/>
    ])
  }
  if(variant === "array"){
    return ([
      <IconButton onClick={() => handleActionButtonClick("DATA")} >
        <InsertChartOutlinedIcon />
      </IconButton>, 
      <IconButton onClick={() => handleActionButtonClick("MAIN")} >
        <SettingsIcon />
      </IconButton>
    ])
  }
  // default: variant === "Stack"
  return (
    <Stack 
      direction="row"
      spacing={1} 
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        }} 
      >
      <IconButton onClick={() => handleActionButtonClick("DATA")}>
        <InsertChartOutlinedIcon />
      </IconButton>
      <IconButton onClick={() => handleActionButtonClick("MAIN")}>
        <SettingsIcon />
      </IconButton>
    </Stack>
  )
}