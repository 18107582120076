export const LOCATION_NAME_LENGTH_MAX = 50;
export const LOCATION_INFO_LENGTH_MAX = 199;

export const locationSortFunc = (a,b) => {
  if(a.children.includes(b)){
    return -1
  }
  if(b.children.includes(a)){
    return 1
  }
  return 0
}