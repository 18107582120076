import { useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';

import { selectAllLocations } from "../../data/locationsSlice.js";
import { selectLocationSubAlarms, findLocationSubAlarmInfo } from "../../data/alarmsSlice.js";

import { 
  Badge as MuiBadge, 
  Icon, 
  IconButton, 
  styled 
} from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const Badge = styled(MuiBadge)(() => ({
  '& .MuiBadge-badge': {
    right: 12,
    top: 6,
  },
}));

// Get alarms of the devices in location with the given locationId.
// If locationId === -1, get alarms of devices without location.
const PureLocationAlarms = ({t, locationId, tableApiRef}) => {
  const locations = useSelector(selectAllLocations);
  const alarms = useSelector(selectLocationSubAlarms(locationId));
  const {
    locationsInPathsToAlarms, 
    locationHasAlarms, 
    isTopNode, 
    alarmCount,
    alarmLocationIds
  } = useSelector(findLocationSubAlarmInfo(locationId));

  const subLocationDeviceCount = (function subLocationDevice(locationId){
    const currentLocation = locations.find( location => location.id === locationId);
    if(currentLocation == null){
      return 0;
    }
    return currentLocation.devices.length + currentLocation.children.reduce( (acc,cur) => acc + subLocationDevice(cur), 0);
  })(locationId);

  // Expand all location in the paths to the locations with alarms
  const handleClick =  () => {
    const locations = locationsInPathsToAlarms.union(alarmLocationIds);
    locations.forEach( locationId => tableApiRef.current.setRowChildrenExpansion(locationId,true));
  }

  // There are no alarms in this locations or under it
  if(alarmCount === 0){
    return (
      <Icon sx={{ml:1.7,mt:-0.9}} disabled color={'disabled'}>
        •
      </Icon>
    )
  }

  // No alarms in this location but there are some under it and this location is expanded
  if(!locationHasAlarms && tableApiRef.current.getRowNode(locationId).childrenExpanded){
    return (
      <Icon sx={isTopNode ? {ml:1,mt:-.2} :  {ml:1.7,mt:-1}}  color={"warning"} fontSize={isTopNode ? "large" : "medium"}>
        •
      </Icon>
    )
  }

  // Location is not expanded and it has alarms or there are some under it.
  // Show different icons depending on wheter there are alarms under it.
  return(
    <IconButton sx={{ml:0.7,mt:0.3}} onClick={handleClick} >
      <Badge badgeContent={alarms.length} max={99} color="warning" >
        { locationHasAlarms ? <ArrowDropDownIcon /> : <ArrowDownwardIcon color={"warning"} /> }
      </Badge>
    </IconButton>
  )
}

const LocationAlarms = withTranslation()(PureLocationAlarms);
export { 
  LocationAlarms 
};