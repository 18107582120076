import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 6.35 6.35"
    stroke="currentColor"
    overflow="hidden"
    fill="none"
  >
    <defs>
      <clipPath id="a">
        <path fillRule="evenodd" d="M642 317h57v55h-57z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="b">
        <path fillRule="evenodd" d="M646 233h42v46h-42z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="c">
        <path fillRule="evenodd" d="M637 138h60v62h-60z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="d">
        <path fillRule="evenodd" d="M341 490h64v60h-64z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="e">
        <path fillRule="evenodd" d="M354 491h21v55h-21z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="f">
        <path fillRule="evenodd" d="M354 491h21v55h-21z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="g">
        <path fillRule="evenodd" d="M354 491h21v55h-21z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="h">
        <path fillRule="evenodd" d="M354 491h21v55h-21z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="i">
        <path fillRule="evenodd" d="M622 30h79v79h-79z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="j">
        <path fillRule="evenodd" d="M622 31h78v77h-78z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="k">
        <path fillRule="evenodd" d="M622 31h78v77h-78z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="l">
        <path fillRule="evenodd" d="M622 31h78v77h-78z" clipRule="evenodd" />
      </clipPath>
    </defs>
    <g
      style={{
        display: "inline",
      }}
    >
      <g
        style={{
          overflow: "hidden",
          fill: "currentColor",
          fillOpacity: 1,
          stroke: "none",
          strokeOpacity: 1,
        }}
      >
        <g
          clipPath="url(#i)"
          style={{
            fill: "currentColor",
            fillOpacity: 1,
            stroke: "none",
            strokeOpacity: 1,
          }}
          transform="matrix(.08793 0 0 .08793 -55.08 -2.94)"
        >
          <path
            fill="none"
            fillRule="evenodd"
            d="M675 44h6M675 50h6M675 57h6M675 63h6M675 69h6"
            style={{
              fill: "currentColor",
              fillOpacity: 1,
              stroke: "none",
              strokeOpacity: 1,
            }}
          />
          <g
            clipPath="url(#j)"
            style={{
              fill: "currentColor",
              fillOpacity: 1,
              stroke: "none",
              strokeOpacity: 1,
            }}
          >
            <g
              clipPath="url(#k)"
              style={{
                fill: "currentColor",
                fillOpacity: 1,
                stroke: "none",
                strokeOpacity: 1,
              }}
            >
              <g
                clipPath="url(#l)"
                style={{
                  fill: "currentColor",
                  fillOpacity: 1,
                  stroke: "none",
                  strokeOpacity: 1,
                }}
              >
                <path
                  d="M38.5 69.22c-5.213 0-9.785-3.69-10.908-8.743-1.203-5.133 1.363-10.347 6.096-12.593V12.593A4.827 4.827 0 0 1 38.5 7.78a4.827 4.827 0 0 1 4.813 4.813v35.291c4.732 2.246 7.218 7.46 6.095 12.593A11.395 11.395 0 0 1 38.5 69.22zm9.625-24.063V12.593c0-5.294-4.331-9.625-9.625-9.625a9.602 9.602 0 0 0-9.625 9.625v32.564c-5.534 4.171-7.78 11.39-5.615 17.887 2.166 6.577 8.342 10.988 15.24 10.988s13.074-4.411 15.24-10.988c2.165-6.497-.08-13.716-5.615-17.887z"
                  style={{
                    fill: "currentColor",
                    fillOpacity: 1,
                    stroke: "none",
                    strokeOpacity: 1,
                  }}
                  transform="matrix(1.01299 0 0 1 622 31)"
                />
                <path
                  d="M40.104 50.13V30.72h-3.208v19.41c-4.01.802-6.818 4.572-6.337 8.663.401 4.09 3.85 7.219 7.941 7.219 4.09 0 7.54-3.129 7.94-7.22.482-4.09-2.325-7.86-6.336-8.662z"
                  style={{
                    fill: "currentColor",
                    fillOpacity: 1,
                    stroke: "none",
                    strokeOpacity: 1,
                  }}
                  transform="matrix(1.01299 0 0 1 622 31)"
                />
              </g>
            </g>
          </g>
        </g>
        <path
          d="M37.463 74.909a16.373 16.373 0 0 1-9.819-4.54 15.675 15.675 0 0 1-4.062-6.497c-1.034-3.147-1.03-6.65.01-9.76a16.531 16.531 0 0 1 2.525-4.784c.549-.728 2.012-2.212 2.725-2.763l.45-.349V29.62c0-11.387.033-16.829.103-17.336.609-4.372 4.172-7.737 8.7-8.215 2.766-.292 5.74.78 7.755 2.795 1.188 1.188 1.962 2.493 2.504 4.22l.261.833.03 17.14.031 17.138.62.475c.84.643 2.13 2.018 2.91 3.098.352.49.931 1.47 1.286 2.18.89 1.78 1.332 3.204 1.582 5.1.582 4.432-.843 9.008-3.887 12.476-3.344 3.81-8.615 5.878-13.724 5.386zm3.699-4.876c2.86-.613 5.27-2.135 6.983-4.41a10.963 10.963 0 0 0 2.089-8.28c-.522-3.459-2.733-6.637-5.677-8.16l-.641-.33-.002-17.899c-.001-15.627-.02-17.975-.145-18.506-.714-3.03-4.203-4.59-7.05-3.153-1.085.548-1.889 1.474-2.384 2.747l-.247.634-.027 18.088-.027 18.089-.758.42c-2.762 1.534-4.784 4.313-5.462 7.508-.23 1.087-.231 3.261-.001 4.32.62 2.858 2.079 5.173 4.33 6.873 1.348 1.018 3.241 1.838 4.84 2.097.392.064.777.131.855.15.345.083 2.677-.049 3.324-.188z"
          style={{
            fill: "currentColor",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.0949519,
            strokeOpacity: 1,
          }}
          transform="matrix(.08793 0 0 .08793 -.39 -.302)"
        />
        <path
          d="M37.411 66.8c-2.87-.634-5.121-2.628-6.022-5.333-.941-2.828-.299-5.747 1.736-7.89 1.042-1.097 2.34-1.887 3.714-2.258l.62-.168V31.809h3.133v19.363l.403.1c1.948.489 3.786 1.824 4.843 3.52 1.615 2.591 1.6 5.654-.04 8.423-.174.294-.68.9-1.123 1.347-1.172 1.18-2.496 1.9-4.103 2.229-.874.18-2.371.184-3.16.01zM53.03 39.025v-.95h5.888v1.9H53.03zM53.03 32.996v-.997h5.888v1.994H53.03zM53.03 26.966v-.95h5.888v1.9H53.03zM53.03 20.035v-.95h5.888v1.9H53.03zM53.03 14.005v-.997h5.888v1.994H53.03z"
          style={{
            fill: "currentColor",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.0949519,
            strokeOpacity: 1,
          }}
          transform="matrix(.08793 0 0 .08793 -.39 -.302)"
        />
      </g>
    </g>
  </svg>
);




