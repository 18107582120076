import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from "react-query";

import logger from '../utility/logger.mjs';
import { timeRanges } from '../utility/influxChartConfig.mjs';
import { 
  useKeyValueQuery,
  usePendingSettingsQuery,
  usePhonenumbersQuery,
  useUiDataQuery,
  useUiDescQuery,
} from '../queries/deviceQuery.js';
import { 
  deviceAdded, 
  selectCurrentDevice, 
  selectCurrentDataIsLoaded,
  selectCurrentActivationTime,
} from '../data/devicesSlice.js';
import { currentPageSet, selectCurrentPage, timeRangeSet } from '../data/uiSlice.js';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';

import SubPageSettings from './SubPageSettings.js';
//import SubPageAdvanced from './SubPageAdvanced';
import SubPageData from './SubPageData.js';
//import SubPageStore from './SubPageStore';
import SubPageDevices from './SubPageDevices.js';
import SubPageLocations from './SubPageLocations.js';
// import { SettingsBackupRestore } from '@mui/icons-material';
import SetupDialog from "../components/SetupDialog.js";
import TermsDialog from '../components/TermsDialog.js';

const subPage = {
  DATA: SubPageData,
  MAIN: SubPageSettings,
  DEVICES: SubPageDevices,
  LOCATIONS: SubPageLocations,
};

const MainPage = ({t}) => {
  const dispatch = useDispatch();
  const currentSubPage = useSelector(selectCurrentPage) || "MAIN";
  const PUBLIC_VERSION = !!Number(process.env.REACT_APP_IS_PUBLIC_VERSION);
  const dataIsLoaded = useSelector(selectCurrentDataIsLoaded);
  const device = useSelector(selectCurrentDevice);
  const deviceId = device.id;

  // set landing subPage
  useEffect(() => {
    if(!Object.keys(subPage).includes(currentSubPage)){
      dispatch(currentPageSet("DEVICES"));
    }
  },[])

  const { data:phonenumbers, status:status2 } = usePhonenumbersQuery({
    deviceId,
    enabled: !dataIsLoaded,
  });

  const { data:uiDesc, status:status3 } = useUiDescQuery({
    deviceId,
    enabled: !dataIsLoaded,
  });
  
  const { data:uiData, status:status4 } = useUiDataQuery({
    deviceId, 
    // construct an array of unique jsonkeys
    jsonkeys: [... new Set(uiDesc?.filter(n => n?.id != null).map((row) => row.jsonkeys)?.flat(1))].filter(n => n),
    commTech: device?.commTech ?? "LTE-M",
    enabled: !!uiDesc 
  });

  const { data:pendingSettings, status:status5, isSuccess:isSuccess5 } = usePendingSettingsQuery({
    deviceId,
    settingsId: uiData?.reduce((acc, cur) => ((acc > cur.settingsId) ? acc : cur.settingsId), 0), 
    enabled: !!uiData
  })

  const { data:keyValues, status:status6, isSuccess:isSuccess6, isError:keyValuesIsError } = useKeyValueQuery({
    device, 
    uiDesc, 
    enabled: !!uiDesc 
  });
  
  // parse json strings and construct the data object 
  const data = {
    ...device,
    phonenumbers,
    settings: uiData?.reduce((acc, cur) => (cur?.value == null ? acc : {...acc, [cur.jsonkey]: cur.value}), {}) ?? null,
    msgId: uiData?.reduce((acc, cur) => (cur?.msgId == null ? acc : {...acc, [cur.jsonkey]: cur.msgId}), {}) ?? null,
    //pendingSettings: (pendingSettings == null || pendingSettings?.length === 0)  ? null : pendingSettings, // set null if array is []
    settingsId: uiData?.reduce((acc, cur) => ((acc > cur.settingsId) ? acc : cur.settingsId), 0), // max settingsId
    settingsTime: uiData?.[0]?.timestamp,
    uiGeneralDesc: uiDesc?.filter(row => row?.id == null)?.[0],
    uiDesc: uiDesc
      ?.filter(row => row?.id != null) // filter out general ui settings
      ?.filter(row => !!Number(row.dev) ? !PUBLIC_VERSION : true) // filter dev version elements if public version
      .map( (row) => {
        const {influxKeySuffix, maxRealVal, minRealVal, step} = uiData?.find( r => (r.jsonkey === row?.jsonkeys?.flat(1)?.[0])) ?? {};
        return ({
            ...row, 
            influxKey: row?.influxKey + (influxKeySuffix ?? ""),
            minMaxRealValues: 
              row?.jsonkeys?.reduce( (acc, cur, i) => ({
                ...acc, 
                [cur]: {
                  max: uiData?.find( r => (r.jsonkey === cur))?.maxRealVal, 
                  min: uiData?.find( r => (r.jsonkey === cur))?.minRealVal, 
                } 
              }), {}) ?? {}, 
            step,
        })
      }),
    data: keyValues != null ? {...keyValues} : {},
  };

  if(isSuccess5 && (status6 === "success" || status6 === "error") && !dataIsLoaded){
    dispatch(deviceAdded(data));
    dispatch(timeRangeSet(timeRanges(data?.data?.referenceValues?.time)[data?.uiGeneralDesc?.defaultTimeRange ?? 4]));
  }

  const handleSubPageChange = (_, newValue) => {
    dispatch(currentPageSet(newValue));
  };

  function SubPageSelector(props) {
    const SelectedSubPage = subPage[currentSubPage]
    return <SelectedSubPage selectSubPage={props.selectSubPage}/>;
  };

  const SetupDialogs = () => {
    if(dataIsLoaded){
      return (
        <>
          <SetupDialog />
          <TermsDialog /> 
        </>
      )
    }
    return false;
  }

  const SupPageTabs = () => {
    return (
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={currentSubPage} 
          onChange={handleSubPageChange} 
          scrollButtons="auto" 
          allowScrollButtonsMobile
          centered 
          >
            <Tab value="DATA" label={t('data-tab')} />
            <Tab value="MAIN" label={t('settings-tab')} />
            <Tab value="DEVICES" label={t('devices-tab')} />
            <Tab value="LOCATIONS" label={t('locations-tab')} />
        </Tabs>
      </Box>
    )
  }

  const Content = () => {
    if((status6 === "success" || status6 === "error") && isSuccess5 ){
      return <SubPageSelector />
    }
    return <CircularProgress />
  }

  return (
    <>
      <SetupDialogs />
      <SupPageTabs />
      <Box sx={{m:3}}>
        <Content />
      </Box>
    </>
  )
}

export default withTranslation()(MainPage);