import React from "react";
import './App.css';
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';

import { themesAndModes } from "./theme.js";
import { selectBrand, selectThemeMode } from "./data/uiSlice.js";
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import { ConfirmDialogProvider } from './components/ConfirmDialog.js';
import { generateToastConfig } from "./utility/toastConfig.js";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const inputGlobalStyles = <GlobalStyles styles={{ 
  '.tick text': {
  'position': 'absolute !important',
  'font-size': '12px'
  },
  '.tick text:hover': {
    'position': 'absolute !important',
    'z-index': '1000 !important',
    'font-size': '15px'
  },
  'body': {
  'margin': 0,
  'font-family': "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',
}
}}/>;

function App() {
  const brand = useSelector(selectBrand);
  const mode = useSelector(selectThemeMode);
  const toastConfig = generateToastConfig({mode});

  return (
    <>
      <ThemeProvider theme={themesAndModes(brand,mode)}>
        <CssBaseline />
        {inputGlobalStyles}
        <ConfirmDialogProvider>
          <ToastContainer 
            hideProgressBar={toastConfig.hideProgressBar}
            autoClose={toastConfig.autoClose} // 12s
            theme={toastConfig.theme}
            />
          <Container className="App" sx={{  display: "flex",
  flexDirection: "column"}} maxWidth="100%" disableGutters="true">
              <Header />
              <Container sx={{minHeight:300}} maxWidth="sm" disableGutters="true">
                <Outlet />
              </Container>
              <Footer />
          </Container>
        </ConfirmDialogProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
