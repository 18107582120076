import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { withTranslation, useTranslation } from 'react-i18next';

import useParamSelector from "../../data/useParamSelector.js";
import { 
  selectLocationByDeviceId,
  selectLocationFullPathById,
  selectLocationRawPathById,
} from "../../data/locationsSlice.js";
import { selectAllAlarms } from "../../data/alarmsSlice.js";
import { selectDeviceById } from "../../data/devicesSlice.js";
import { PrettyTime } from "../PrettyTime.js";
import { AlarmIcon } from "../Icons/AlarmIcon.js";
import { AlarmDialog } from "./AlarmDialog.js";
import { AlarmTypesInfoDialog } from "./AlarmTypesInfo.js";
import { OpenButton } from "../InfoDialog.js";
import useDataGridLocaleText from "../Locations/useDataGridLocaleText.js";

import {
  AppBar,
  Box,
  Container,
  Dialog,
  IconButton,
  ListItemText,
  Toolbar, 
  Tooltip,
  Typography,
} from "@mui/material";
import { 
  DataGridPro, 
  useGridApiContext, 
  gridClasses 
} from "@mui/x-data-grid-pro";
import LaunchIcon from '@mui/icons-material/Launch';
import CloseIcon from '@mui/icons-material/Close';

const HeaderBar = withTranslation()(({t,closeFunction}) => {
  return (
    <AppBar sx={{ position: 'relative' }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={closeFunction}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
          {t("alarms")}
        </Typography>
        <AlarmTypesInfoDialog />
      </Toolbar>
    </AppBar>
  )
})

const DeviceCell = ({row}) => {
  const device = useSelector(selectDeviceById(row.deviceId));
  const location = useSelector(selectLocationByDeviceId(row.deviceId));
  const path = useParamSelector(selectLocationFullPathById, location?.id) ?? "";

  return (
      <ListItemText primary={device.name} secondary={path} />
  )
}

const AlarmRowActions = ({row,locationTableApiRef,closeFunction}) => {
  const {t} = useTranslation();
  const location = useSelector(selectLocationByDeviceId(row.deviceId));
  const rawPath = useParamSelector(selectLocationRawPathById, location?.id);

  const handleShowLocationClick = () => {
    // First, collapse all rows. Then expand rows with alarms.
    if(rawPath.length > 0){
      const rowIds = locationTableApiRef.current.getAllRowIds();
      rowIds.forEach( id => {
        if(locationTableApiRef.current.getRowNode(id).type === 'group'){
          locationTableApiRef.current.setRowChildrenExpansion(id, false)
        }
      });
      closeFunction();
      rawPath.forEach(locationId => locationTableApiRef.current.setRowChildrenExpansion(locationId,true));
      locationTableApiRef.current.scrollToIndexes(`d${row.deviceId}`);
    }
  }
  return ([
    <Tooltip title={t('show-location')} >
      <IconButton onClick={handleShowLocationClick}><LaunchIcon /></IconButton>
    </Tooltip>,
    <AlarmDialog alarm={row} openButtonType={"openIcon"} tooltipTitle={t('info')} />
  ])
}

// table styles
const dataGridStyles = {
  padding: 1,
  paddingBottom: 2,
  mx: -2,
  // disable focus 
  [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
      outline: 'none',
    },
  [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
      outline: 'none',
    },
};

export default withTranslation()(({t}) => {
  const locationTableApiRef = useGridApiContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const closeFunction = () => setDialogOpen(false);
  const alarms = useSelector(selectAllAlarms)
    .toSorted( (a,b) =>  new Date(b.alarmStamp).getTime() - new Date(a.alarmStamp).getTime());
  const activeAlarms = alarms.filter( alarm => !alarm.alarmAckedByUserPhone && !alarm.alarmAckedByUserTG);

  const columns = useMemo(
    () => [
      {
        field: 'type',
        headerName: '',
        width: 50,
        minWidth: 50,
        align:'center',
        display: 'flex',
        renderCell: ({row}) => <AlarmIcon type={row.type} />,
      },
      {
        field: 'name',
        headerName: t('device'),
        display: 'flex',
        flex:  1,
        renderCell: DeviceCell
      },
      {
        field: 'alarmStamp',
        headerName: t('time'),
        display: 'flex',
        flex: 0.5,
        renderCell: ({value}) => <PrettyTime>{value}</PrettyTime>,
        sortComparator: (time1, time2) => new Date(time1) - new Date(time2),
      },
      {
        field: 'actions',
        headerName: '',
        type: 'actions',
        width: 80,
        getActions: ({row}) => AlarmRowActions({row,locationTableApiRef,closeFunction}),
      },
    ]
  );

  const alarmCount = activeAlarms.length;
  const tableHeight = alarmCount < 10 
    ? Math.max(200, 70 + alarmCount * 96)
    : 400;

  return (
    <>
      <OpenButton 
        buttonText={t('alarms')} 
        openFunc={() => setDialogOpen(true)}
        disabled={alarmCount === 0}
      />
      <Dialog 
        fullScreen
        open={dialogOpen}
        onClose={closeFunction}
      >
        <HeaderBar closeFunction={closeFunction} />
        <Container maxWidth="sm" sx={{height: tableHeight, mt: 2}} >
          <DataGridPro 
            localeText={useDataGridLocaleText()}
            getRowHeight={() => 'auto'}
            columns={columns}
            rows={activeAlarms}
            disableColumnMenu 
            hideFooter
            slots={{
              noRowsOverlay: t('no-alarms'),
            }}
            sx={dataGridStyles}
          />
        </Container>
      </Dialog>
    </>
  )
})