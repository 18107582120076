import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg 
    viewBox="0 0 6.35 6.35" 
    stroke="currentColor"
    >
    <path
      d="M8.993 4.14h5.171s.6.068.671-.335c-.037-.305-.37-.352-.444-.159m-5.058.918h4.592m-4.04.434h3.505s.327-.046.434.152c.055.1.118.335-.163.491"
      style={{
        fill: "none",
        strokeWidth: 0.35,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeDasharray: "none",
        paintOrder: "stroke fill markers",
      }}
      transform="translate(-8.735 -.047)"
    />
    <path
      d="M11.912 6.18c-.096-.012-.772-.113-1.399-.766m-.9-1.714a7.529 7.529 0 0 1-.217-1.877c1.933.349 2.537-1.43 2.537-1.43s.604 1.776 2.536 1.427c0 .498-.036.939-.1 1.33M13.3 5.465c-.657.651-1.347.71-1.347.71"
      style={{
        display: "inline",
        fill: "none",
        strokeWidth: 0.377,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeDasharray: "none",
        paintOrder: "stroke fill markers",
      }}
      transform="translate(-8.735 -.047)"
    />
    <g
      style={{
        strokeWidth: 0.624227,
        strokeDasharray: "none",
      }}
    >
      <path
        d="M3.337-9.367v4.161L1.92-6.623h1.336m.101-2.737v4.16l1.417-1.417H3.437"
        style={{
          fill: "currentColor",
          fillOpacity: 1,
          strokeWidth: 0.624227,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeDasharray: "none",
          paintOrder: "stroke fill markers",
        }}
        transform="translate(1.57 6.152) scale(.4806)"
      />
    </g>
  </svg>
);




