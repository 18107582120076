import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 6.35 6.35"
    stroke="currentColor"
    overflow="hidden"
    fill="none"
  >
    <defs>
      <clipPath id="a">
        <path fillRule="evenodd" d="M642 317h57v55h-57z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="b">
        <path fillRule="evenodd" d="M646 233h42v46h-42z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="c">
        <path fillRule="evenodd" d="M637 138h60v62h-60z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="d">
        <path fillRule="evenodd" d="M341 490h64v60h-64z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="e">
        <path fillRule="evenodd" d="M354 491h21v55h-21z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="f">
        <path fillRule="evenodd" d="M354 491h21v55h-21z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="g">
        <path fillRule="evenodd" d="M354 491h21v55h-21z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="h">
        <path fillRule="evenodd" d="M354 491h21v55h-21z" clipRule="evenodd" />
      </clipPath>
    </defs>
    <g
      style={{
        display: "inline",
      }}
    >
      <g
        clipPath="url(#d)"
        style={{
          display: "inline",
          overflow: "hidden",
        }}
        transform="matrix(.09783 0 0 .09783 -33.319 -47.72)"
      >
        <g clipPath="url(#e)">
          <g clipPath="url(#f)">
            <g fill="none"  clipPath="url(#g)">
              <g strokeWidth={4} clipPath="url(#h)">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeMiterlimit={10}
                  d="M373 494v49.625"
                />
                <path strokeMiterlimit={8} d="M356 508v23.75" />
                <path
                  strokeLinecap="round"
                  strokeMiterlimit={8}
                  d="m356 508 16.25-14M356 532l16.25 11.875"
                />
              </g>
              <path
                strokeLinecap="round"
                strokeWidth={3.599}
                d="M353.955 531.36c-4.818 0-9.265-2.187-11.65-5.731-2.385-3.543-2.342-7.898.114-11.406 2.456-3.509 6.946-5.633 11.763-5.564"
              />
              <path
                strokeLinecap="round"
                strokeWidth={3.916}
                d="M383.63 496.021c5.064 0 9.739 4.119 12.246 10.79 2.508 6.672 2.462 14.87-.12 21.477-2.581 6.606-7.301 10.605-12.365 10.475"
              />
              <path
                strokeLinecap="round"
                strokeWidth={4.019}
                d="M393.614 488.483c8.076 0 15.531 5.696 19.529 14.92 3.998 9.225 3.925 20.56-.191 29.694-4.117 9.134-11.644 14.663-19.718 14.484"
              />
            </g>
          </g>
        </g>
        <path
          fill="none"
          strokeLinecap="round"
          strokeMiterlimit={8}
          strokeWidth={3}
          d="M385.395 493.845c11.725 2.398 19.71 16.5 17.835 31.497-1.383 11.063-7.874 19.947-16.41 22.458l-.108-.6c11.242-3.349 18.245-17.795 15.643-32.265-1.916-10.651-8.65-18.75-17.036-20.487z"
        />
        <path
          fill="none"
          strokeLinecap="round"
          strokeMiterlimit={8}
          strokeWidth={3}
          d="M382.999 502.851c6.926 3.886 10.302 14.938 7.541 24.685-1.319 4.658-3.893 8.392-7.16 10.39l-.216-.699c6.459-4.079 9.383-14.874 6.532-24.112-1.343-4.348-3.816-7.777-6.896-9.562z"
        />
        <path
          fillRule="evenodd"
          strokeMiterlimit={8}
          strokeWidth={4}
          d="M353.112 531.859c-5.802-.336-10.214-5.661-9.853-11.894.32-5.541 4.336-10.046 9.479-10.632-5.786.66-10.016 6.227-9.449 12.434.504 5.518 4.666 9.794 9.823 10.092z"
        />
      </g>
    </g>
  </svg>
);




