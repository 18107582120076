import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';

import logger from '../utility/logger.mjs';
import useParamSelector from '../data/useParamSelector.js'
import { 
  currentSettingsSet,
  selectComponentValues,
  selectCurrentDevice,
  selectCurrentDeviceIndex, 
  selectCurrentNewSettings,
  selectCurrentComponentDesc
} from '../data/devicesSlice.js';

import {
  FormControl,
  FormLabel,
  Stack,
  Switch as MuiSwitch,
  Typography,
} from "@mui/material/";


const calculateInitialValue = (values, switchValues, isMultiguardPIR) => {
  if(isMultiguardPIR){
    return values[1] && values[2] // alfPIRlowLimitEnable && alfPIRhighLimitEnable
  }
  const initialValue = switchValues.indexOf(values);
  return initialValue === -1 ? false : !!initialValue
}

// const calcualteSettingValues = (newValues,switchValues) => {
//   if(multiguardPIR){

//   }
//   return switchValues[Number(newValue)]
// }

function PureSwitch({ t, block }) {
  const dispatch = useDispatch();
  const disabled = block.disabled ?? false;
  const componentId = block.id;
  const data = useParamSelector(selectCurrentComponentDesc, componentId); //useSelector(state => selectCurrentComponentDesc(state, componentId))
  const desc = data.description;
  // const orientation = desc?.ORIENTATION;
  // const position = desc?.POSITION;
  // LEBEL is an array of label/value pairs: [[label1, value1],[label2, value2],...]
  const labels = desc?.LABELS.map( l => l?.[0] ?? "") ?? ["off","on"];
  const isMultiguardPIR = desc?.multiguardPIRswitch ?? false;
  const jsonkeys =  data.jsonkeys;
  const switchValues = desc?.LABELS?.map( (l,i) => l?.[1] ?? i) ?? [0,1];
  const title = desc?.TITLE;
  const valInd = data.JSONValInd;
  const componentValues = useParamSelector(selectComponentValues, componentId); 
  const [value, setValue] = useState(calculateInitialValue(componentValues,switchValues,isMultiguardPIR))

  const handleChange = (event, newValue) => {
    let fixedValues = switchValues[Number(newValue)];
    let fixedJsonkeys = jsonkeys;
    if(isMultiguardPIR){
      const entries = Object.entries(switchValues[Number(newValue)]);
      fixedJsonkeys = entries.map( e => e[0]);
      fixedValues = entries.map( e => e[1]);
    }
    setValue(newValue);
    dispatch(currentSettingsSet({
      values: fixedValues,
      jsonkeys: fixedJsonkeys,
      valInd
    }));
  };
  
  return (
    <FormControl style={{textAlign: "left", marginLeft: "1em"}}>
      <FormLabel>{t(title)}</FormLabel>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{t(labels[0])}</Typography>
        <MuiSwitch 
          disabled={disabled}
          checked={value}
          onChange={handleChange} 
          />
        <Typography>{t(labels[1])}</Typography>
      </Stack>
    </FormControl>
  );
};


const Switch = withTranslation()(PureSwitch);

export {
  Switch
};