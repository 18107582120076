import { useState } from "react";
import { useDispatch } from "react-redux";
import { withTranslation } from 'react-i18next';
import useParamSelector from "../data/useParamSelector";

import { 
  currentSettingsSet,
  selectCurrentComponentDesc,
  selectComponentValueObject
} from "../data/devicesSlice";

import {
  Box,
  FormControl,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material/';

const calculateInitialValues = (componentValues,buttons) => {
  let values = [];
  for(const b of buttons){
    for(const [k,v] of Object.entries(b.value.off)){
      if(componentValues?.[k] == null || componentValues[k] !== v){
        values.push(b.label);
        break;
      }
    }
  }
  return values;
}

function PureToggleButtons({ t, block }) {
  const dispatch = useDispatch();
  const disabled = block.disabled ?? false;
  const componentId = block.id;
  const data = useParamSelector(selectCurrentComponentDesc, componentId); //useSelector(state => selectCurrentComponentDesc(state, componentId))
  const desc = data.description;
  // const orientation = desc?.ORIENTATION;
  // const position = desc?.POSITION;
  // LEBEL is an array of label/value pairs: [[label1, value1],[label2, value2],...]
  const buttons = desc?.BUTTONS ?? [];
  const jsonkeys =  data.jsonkeys;
  const title = desc?.TITLE;
  const valInd = data?.JSONValInd;
  const componentValues = useParamSelector(selectComponentValueObject, componentId); 
  const [values, setValues] = useState(calculateInitialValues(componentValues,buttons));

  const handleChange = (event, newValue) => {
    let changeType = "";
    let label = "";
    if(values.length > newValue.length){
      changeType = "off";
      label = values.filter(key => !newValue.includes(key))[0];
    }
    else {
      changeType = "on";
      label = newValue.filter(key => !values.includes(key))[0];
    }
    const settingsArray = Object.entries(desc.BUTTONS.find( row => row["label"] === label).value[changeType]);
    const settingsValues = settingsArray.map( r => r[1]);
    const settingsJsonkeys = settingsArray.map( r => r[0]);
    dispatch(currentSettingsSet({
      values: settingsValues,
      jsonkeys: settingsJsonkeys,
      valInd
    }));
    setValues(newValue);
  };

  return (
    <FormControl >
      <FormLabel sx={{textAlign: "left", marginLeft: "1em"}}>{t(title)}</FormLabel>
      <Box style={{display: "flex",
  justifyContent: "center"}}>
      <ToggleButtonGroup
        size="large"
        disabled={disabled}
        //orientation={orientation}
        value={values}
        color="primary"
        onChange={handleChange}
      >
      
        {buttons.map ( (b) =>
          <ToggleButton 
            key={b.label} 
            value={b.label} 
          >
            <Tooltip title={t(b.label)}>
              {t(`${b.label}-abbreviation`)}
            </Tooltip>
          </ToggleButton>
        )}

      </ToggleButtonGroup>
      </Box>
    </FormControl>
  );

};

const ToggleButtons = withTranslation()(PureToggleButtons);

export {
  ToggleButtons
};