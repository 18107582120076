import { IconButton, Tooltip } from "@mui/material";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { t } from "i18next";

// icon buttons for different types and states
const icon = {
  tree: {
    isOpen: <KeyboardArrowDown />,
    isClose: <KeyboardArrowRight />,
    disabled: <RemoveIcon />
  },
  collapse: {
    isOpen: <KeyboardArrowUp />,
    isClose: <KeyboardArrowDown />,
    disabled: <RemoveIcon />
  }
}

// [Animated TODO!] button for collapsing a row in table or elsewhere. 
// PROPS: 
// open: boolean controlling state 
// onClick: a functio called when button is cliked
// disabled: boolean; disabled button shown grey
// type: tree (subdirectoryarrow) | collapse (arrow up/down; default)
export default function CollapseButton ({open, onClick, disabled, type:typeProp, hide}) {
  const type = ["tree","collapse"].includes(typeProp) ? typeProp : "collapse";
  
  if(disabled || hide){
    return (
      <IconButton
        aria-label="notExandable"
        size="small"
        style={{ backgroundColor: 'transparent', highlightColor: 'transparent', color: hide ? 'transparent' : '' }}
        disabled
      >
        { icon[type].disabled }
      </IconButton>
    )
  }
  return (
    <Tooltip title={open ? t('collapse') : t('expand')} >
      <IconButton
        aria-label="expandRow"
        size="small"
        onClick={onClick}
      >
        {open ? icon[type].isOpen : icon[type].isClose }
      </IconButton>
    </Tooltip>
  )
}