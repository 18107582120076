import { withTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from "react-query";

import logger from '../utility/logger.mjs';
import { currentPageSet, uiInit } from '../data/uiSlice.js';
import { loginWithPin, } from "../server/serverOperation.js";

import { ButtonWide } from '../components/Button.js';
import { AlertError } from '../components/Alert.js';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';


const DeviceInfo = ({ t, setIsPinOK }) => {
  const dispatch = useDispatch();
  dispatch(currentPageSet("urlLogin"));
  const [errorMessage, setErrorMessage] = useState("");
  const [pin, setPin] = useState("");
  const device = useSelector((state) => state.devices.device[0]);

  function handleClick() {
    if(pin.length > 0){
      executePINlogin();
    }
    else {
      setErrorMessage("Please, enter a PIN.")
    }
  };

  function handleKeyPress(event) {
    if(event.key === 'Enter'){
      handleClick();
    }
  };

  const { 
      data:PINloginData, 
      refetch:executePINlogin, 
      status, 
      error, 
      isLoading, 
      isSuccess:pinCheckIsSuccess 
    } = useQuery(
        ["checkPIN", pin],
        () => {
            return loginWithPin(device.uuid, pin);
        },
        { enabled: false }
  );


  useEffect(() => {
    if(PINloginData != null) {
      setIsPinOK(PINloginData?.pin);
      dispatch(uiInit(PINloginData));
    }
    if(PINloginData?.error) {
      logger.error("error",PINloginData.error);
      setErrorMessage(PINloginData?.error, error);
    }
  },[status,PINloginData])


  return (
    <Box>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{'font-weight': '700'}}>{t('device-name')}</Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="xortecFore">
            {device.name}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{'font-weight': '700'}}>{t('device-type')}</Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="xortecFore">
            {device.type}
          </Box>
        </Grid>
        
        
      </Grid>
      <p>{t("enter-pin")}</p>
      <AlertError title={false} text={errorMessage} />
      <Input 
        id="deviceId" 
        type="text" 
        name="devicename"
        placeholder="pin"
        onChange={ e => setPin(e.target.value.trim()) }
        inputProps={{style: { textAlign: 'center' }}}
        onKeyPress={ handleKeyPress }
      />
      <ButtonWide text="login" onClick={ handleClick } isLoading={isLoading}/>
    </Box>
  );
}

export default withTranslation()(DeviceInfo);