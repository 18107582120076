import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import logger from '../../utility/logger.mjs';
import { 
  dropLocation,
  updateLocation  
} from '../../server/serverOperation'
import { selectCurrentProjectId } from '../../data/uiSlice';
import { 
  locationUpdated, 
  selectLocationById
} from '../../data/locationsSlice';

import { DataList } from '../DataList/DataList';
import { ButtonSmall } from '../Button';
import { RawLocationSelect } from './LocationSelect';
import { LocationTypeSelect } from './LocationTypeSelect';
import useConfirm from '../ConfirmDialog';
import TextInput from '../DataList/TextInput';

import { useGridApiContext } from '@mui/x-data-grid-pro';
import {
  Box,
  Card,
  CardContent,
  Stack,
  Typography, 
} from '@mui/material/';

import { 
  LOCATION_INFO_LENGTH_MAX, 
  LOCATION_NAME_LENGTH_MAX 
} from '../../utility/locationConfig.mjs';


// COMPONENT: Detail panel that opens under a location row. 
const PureLocationDetails = ({t, locationId}) => {
  const apiRef = useGridApiContext();
  const dispatch = useDispatch()
  const confirm = useConfirm();

  const location = useSelector(selectLocationById(locationId))
  const projectId = useSelector(selectCurrentProjectId);

  const [newLocationName, setNewLocationName] = useState(location.name);
  const [newLocationType, setNewLocationType] = useState(location.type);
  const [newLocationInfo, setNewLocationInfo] = useState(location.info ?? '');
  const [newParentLocationId, setNewParentLocationId] = useState(location.parentId);

  /// BUGI: JOS SIIRRETÄÄN KOHDE, JOLLA ON ALAKOHTEITA, ALAKOHTEET EIVÄT SIIRRY MUKANA
  const { mutate:mutateLocations, isLoading:mutateLocationsIsLoading } = useMutation(
    updateLocation, // parameter {id, projectId, parentId, name, type, info}
    {
      onSuccess: () => {
        const updatedLocation = {
          id: locationId,
          projectId,
          parentId:newParentLocationId,
          name: newLocationName,
          info: newLocationInfo,
          type: newLocationType,
        }
        let newPath = location.path;
        // Update path if the target location is moved.
        if(newParentLocationId !== location.parentId){
          if(newParentLocationId > 0){
            newPath = apiRef.current.getRow(newParentLocationId).path.concat([locationId]);
          }
          else{ // the target location is moved to the top
            newPath = [locationId];
          }
        }
        // FOR REDUX SLICE:
        dispatch(locationUpdated(updatedLocation));
        // FOR DATA GRID: 
        apiRef.current.updateRows([{...updatedLocation, path: newPath}]);

        toast.success(`${t("location-updated")}: ${newLocationName}`, {
          toastId: `location-updated-${newLocationName}`
        });
      },
      onError: (error) => {
        toast.error(t("location-edit-error"), {
          toastId: "location-edit-error"
        });
        logger.error("updateLocation", error);
      }
    }
  );

  const handleLocationSaveClick = async () => {
    if(newLocationName.length > 0){
      mutateLocations({
        locationId, 
        parentId: newParentLocationId, 
        name: newLocationName, 
        type: newLocationType, 
        info: newLocationInfo,
      });
    }
    else {
      toast.warning(t("please-give-a-name"));
    }
  }

  const handleResetClick = () => {
    setNewLocationName(location.name);
    setNewLocationType(location.type);
    setNewLocationInfo(location.info ?? '');
    setNewParentLocationId(location.parentId);
  }

  const isLoading = mutateLocationsIsLoading;
  const disabled = isLoading;

  // List of information about the location.
  // Array of rows with of a header and conents.
  const locationDataForList = [
    [
      t("location-name"), 
      <TextInput 
        variant={'location-name'}
        value={newLocationName}
        disabled={disabled}
        onChange={e => setNewLocationName(e.target.value)}
        inputProps={{maxLength: LOCATION_NAME_LENGTH_MAX}}
      />
    ],
    [
      t("location-info"), 
      <TextInput 
        placeholder={t('location-info-placeholder')}
        value={newLocationInfo}
        disabled={disabled}
        onChange={e => setNewLocationInfo(e.target.value)}
        inputProps={{maxLength: LOCATION_INFO_LENGTH_MAX}}
      />
    ],
    [
      t("type"),  
      <LocationTypeSelect
        sx={{width:"100%"}}
        type={newLocationType} 
        onChange={e => setNewLocationType(e.target.value)}
      />
    ],
    [
      t("position"), 
      <RawLocationSelect 
        sx={{width:"100%"}}
        filterSeeds={[location.id]}
        value={newParentLocationId}
        onChange={e => setNewParentLocationId(e.target.value)}
      />
    ]
  ];

  

  return (
    <Box sx={{width: '350px',}}>
    <Card variant="outlined" >
      <CardContent>
        <Stack 
          direction="row" 
          spacing={1} 
          sx={{
            mx:1,
            justifyContent: "space-between",
            alignItems: "center",
            }}
          >
          <Typography gutterBottom variant="h2" component="div">
            {t('location')}
          </Typography>
      </Stack>
      <DataList 
            data={locationDataForList}
            key={locationId} 
          />
      </CardContent>
      <Stack direction="row" sx={{mb: 1, mt: -1, justifyContent: "space-around",}}>
        <ButtonSmall 
          sx={{m: 0}}
          text="clear" 
          onClick={handleResetClick} 
          disable={isLoading}
          />
        <ButtonSmall 
          sx={{m: 0}}
          text="save" 
          onClick={handleLocationSaveClick}
          disable={isLoading}
          />
      </Stack>
    </Card>
    </Box>
  )
}
const LocationDetails = withTranslation()(PureLocationDetails);

export default LocationDetails;