import { 
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";

import { selectCurrentProjectId } from "./uiSlice";
import { findById, findIndexById } from "../utility/function.mjs";
import { locationSortFunc } from "../utility/locationConfig.mjs";

const locationTypeSortFunc = (a,b) => {
  if(a.weight < b.weight){
    return -1
  }
  if(b.weight < a.weight){
    return 1
  }
  return 0
}

const initialState = {
  dataIsLoaded: false,
  showAllChildren: false,
  editMode: false,
  project: [],
  location: [],
  locationType: [],
  modifiedLocations: [],
  error: null,
};


const getPath = (id, locations) => {
  const path = [];
  (function constructPathRecursion(id) {
    path.push(id);
    const parentId = findById(locations, id).parentId;
    if(parentId == null){
      return path
    }
    return constructPathRecursion(parentId)
  })(id)
  return path.reverse();
} 

export const deviceMoved = createAsyncThunk(
  'locations/deviceMoved',
  ({locationId:targetLocationId, deviceId}, { getState, rejectWithValue }) => {
    const locations = JSON.parse(JSON.stringify(getState().locations.location));
    let targetLocation = {};
    let oldParentLocation = {};
    let modifiedLocations = [];
    const targetI = findIndexById(locations, targetLocationId);
    const deviceI = findIndexById(getState().devices.device, deviceId);
    if( targetLocationId !== -1 && targetI === -1 ){
      return rejectWithValue("locations/movedToLocation: given location id is not valid");
    }
    if((deviceI === -1) ){
      return rejectWithValue("locations/movedToLocation: given device id is not valid");
    }
    const oldParentLocationI = locations.findIndex( l => l.devices.includes(deviceId));
    // If the device had was located somewhere, remove its id from children list of the location
    if(oldParentLocationI !== -1){
      oldParentLocation = locations[oldParentLocationI];
      oldParentLocation.devices.splice(oldParentLocation.devices.indexOf(deviceId),1);
      modifiedLocations.push(oldParentLocation.id);
    }
    // If device is moved to a location, add its id into the locations children list
    if(targetI !== -1){
      targetLocation = locations[targetI];
      targetLocation.devices.push(deviceId);
      modifiedLocations.push(targetLocationId);
    } 
    return {newLocations:locations, newModifiedLocations:modifiedLocations};
  }
);

export const locationDeleted = createAsyncThunk(
  'locations/locationDeleted',
  ({id}, { getState, rejectWithValue }) => {
    const locations = JSON.parse(JSON.stringify(getState().locations.location));
    const i = findIndexById(locations,id);
    if(i === -1){
      return rejectWithValue("locationDeleted: the target location not found");
    }
    if(locations[i].children.length > 0){
      return rejectWithValue("locationDeleted: the target location has children -- cannot be deleted");
    }
    const parentI = findIndexById(locations, locations[i].parentId);
    // Remove the target location from locations
    locations.splice(i,1);
    // Remove id of the target location from the list of children of its parent location 
    if(parentI !== -1){
      const childI = locations[parentI].children.indexOf(id);
      locations[parentI].children.splice(childI,1);
    }
    return {newLocations:locations};
  }
);

export const expandAlarmLocations = createAsyncThunk(
  'locations/expandAlarmLocations',
  ({alarms}, { getState, rejectWithValue }) => {
    if(alarms == null || alarms.length === 0){
      rejectWithValue("expandAlarmLocations: alarms undefined")
    }
    const newLocations = JSON.parse(JSON.stringify(getState().locations.location));
    const openSupLocations = (id) => {
      if(id == null){
        return;
      }
      const currentLocation = findById(newLocations,id);
      currentLocation.showChildren = true;
      openSupLocations(currentLocation.parentId)
    }
    for (let alarm of alarms) {
      const location = newLocations.find( l => l.devices.includes(alarm.deviceId));
      if(location?.id != null){
        openSupLocations(location.id);
      }
    }
    return {newLocations}
  }
);

export const locationsSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    locationsInit: (state, action) => {
      state.project = action.payload.projects;
      state.location = action.payload.locations.map( l => {
          const children = action.payload.locations.filter( loc => l.id === loc.parentId).map( loc => loc.id);
          const devices = l?.devices == null ? [] : l.devices.split(",").map( item => parseInt(item));
          const path = getPath(l.id, action.payload.locations);
          return {...l, children, devices, showChildren: false, path}
        })
        .sort(locationSortFunc) ?? [];
      const locationType = action.payload?.locationTypes?.toSorted(locationTypeSortFunc).map( t => t.name);
      state.locationType = locationType ?? [];
      state.dataIsLoaded = true;
    },
    editModeTogled: (state, _) => {
      state.editMode = !state.editMode;
    },
    locationAdded: (state, action) => {
      const {id, projectId, name, parentId, info, type, showParentChildren} =  action.payload;
      if(projectId != null && name != null){
        const parentLocationIndex = findIndexById(state.location,parentId);
        if(parentLocationIndex != -1){
          state.location[parentLocationIndex].children.push(id);
          state.location[parentLocationIndex].showChildren = showParentChildren ?? true;
        }
        const path = parentLocationIndex === -1 
            ? [id]
            : state.location[parentLocationIndex].path.concat([id]);
        const newLocation = {
            id,
            projectId,
            parentId: parentId < 1 ? null : parentId,
            name,
            info,
            type: type ?? "other",
            children: [],
            devices: [],
            path,
            showChildren: false,
          }
        state.location.push(newLocation);
        state.location.sort(locationSortFunc)
      }
    },
    locationUpdated: (state, action) => {
      const {id, projectId, parentId, name, info, type,} = action.payload;
      const i = findIndexById(state.location, id);
      const oldParentId = state.location[i].parentId;
      const oldParentLocationInd = findIndexById(state.location, oldParentId);
      let path = state.location[i].path;
      // If the location is moved, update its path, update the old parent location's list of children
      // and update the new parent location's list of children if needed.
      if(parentId !== oldParentId){
        const newParentLocationInd = findIndexById(state.location, parentId);
        path = newParentLocationInd === -1
                  ? [id]
                  : state.location[newParentLocationInd].path.concat([id]);
        if(oldParentLocationInd !== -1){
          const ind = state.location[oldParentLocationInd].children.indexOf(id);
          state.location[oldParentLocationInd].children.splice(ind,1);
        }
        if(newParentLocationInd !== -1){
          state.location[newParentLocationInd].children.push(id);
        }
      }
      state.location[i] = {
        ...state.location[i],
        id, 
        projectId, 
        parentId: parentId < 1 ? null : parentId,
        name, 
        info, 
        type,
        path
      }
      state.location.sort(locationSortFunc);
    },
    locationNameSet: (state, action) => {
      const i = findIndexById(state.location, action.payload.id);
      state.location[i].name = action.payload.newName;
    },
    showChildrenTogled: (state, action) => {
      const i = state.location.findIndex( l => l.id === action.payload);
      if(state.location[i].showChildren === false){
        state.showAllChildren = true;
      }
      if(i > -1){
        state.location[i].showChildren = !state.location[i].showChildren;
      }
    },
    showAllChildrenTogled: (state, _) => {
      const newValue = !state.showAllChildren;
      state.location = state.location.map( l => ({...l, showChildren: newValue}) );
      state.showAllChildren = newValue;
    },
  },
  extraReducers(builder) {
      builder
        .addCase(deviceMoved.fulfilled, (state, action) => {
        return {
          ...state,
          location: action.payload.newLocations,
          modifiedLocations: [...state.modifiedLocations, ...action.payload.newModifiedLocations]
        }
        })
        .addCase(locationDeleted.fulfilled, (state, action) => {
          return {
            ...state,
            location: action.payload.newLocations
          }
        })
        .addCase(locationDeleted.rejected, (state, action) => {
          state.error = action.payload
        })
        .addCase(expandAlarmLocations.fulfilled, (state,action) => {
          state.location = action.payload.newLocations
        })
  }
});

export const selectAllLocations = state => state.locations.location;

export const selectAllLocationTypes = state => state.locations.locationType;

export const selectLocationById = id => state => {
  if(id == null){
    return state.locations.location.filter( l => l.parentId === null ).map( l => l.id );
  }
  return state.locations.location.find( l => l.id === id);
}

export const selectCurrentProjectLocations = createSelector(
  [selectAllLocations, selectCurrentProjectId],
  (locations, currentProjectId) => locations.filter( l => (l.projectId === currentProjectId))
);

export const selectCurrentProjectLocationById = createSelector(
  [selectCurrentProjectLocations, (_,locationId) => locationId],
  (locations, locationId) => {
    if(locationId == null){
      return locations.filter( l => l.parentId === null ).map( l => l.id );
    }
    return locations.find( l => l.id === locationId);
  }
);

export const selectLocationByDeviceId = deviceId => state => {
  return state.locations.location.find( l => l.devices.includes(deviceId));
}

export const selectAllCurrentProjectBoundDevices = createSelector(
  (...args) => selectCurrentProjectLocations(...args), 
  locations => locations.reduce( (a, l) => a.concat(l.devices), [])
);

export const selectAllBoundDevices = state => state.locations.location.reduce( (a, l) => a.concat(l.devices), []);

export const selectLocationPrettyPathById = createSelector(
  [state => state.locations.location, (_,locationId) => locationId],
  (locations, locationId) => {
    const path = findById(locations, locationId)?.path;
    if(path == null){
      return "";
    }
    return path.slice(0,-1).map( id => findById(locations, id).name).join(' » ');
  }
);

export const selectLocationRawPathById = createSelector(
  [state => state.locations.location, (_,locationId) => locationId],
  (locations, locationId) => {
    return findById(locations, locationId)?.path;
  }
);

export const selectLocationFullPathById = createSelector(
  [state => state.locations.location, (_,locationId) => locationId],
  (locations, locationId) => {
    const path = findById(locations, locationId)?.path;
    if(path == null){
      return "";
    }
    return path.map( id => findById(locations, id).name).join(' » ');
  }
);


export const { 
  locationsInit,
  editModeTogled,
  locationAdded,
  locationUpdated,
  locationNameSet,
  showAllChildrenTogled,
  showChildrenTogled,
} = locationsSlice.actions;

export default locationsSlice.reducer;