import { Input } from "@mui/material"

export default function TextInput({value, placeholder, disabled, onChange, inputProps, sx}) {
  return (
    <Input 
      sx={{width:"100%", ...sx}}
      multiline
      maxRows={8}
      id="TextField" 
      type="text" 
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      inputProps={inputProps}
    />
  )
}