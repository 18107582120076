import { useQuery } from "react-query";

import { getInfluxDesc } from "../utility/influxChartConfig.mjs";
import { 
  fetchKeyValues ,
  fetchPendingSettings,
  fetchPhonenumbers,
  fetchUiData,
  fetchUiDesc,
} from "../server/serverOperation";
import { useSelector } from "react-redux";

export const usePhonenumbersQuery = ({deviceId, enabled}) => {
  return useQuery(
    ["phonenumbers", deviceId],
    () => fetchPhonenumbers(deviceId),
    { enabled }
  );
}

export const useUiDescQuery = ({deviceId, enabled}) => {
  return useQuery(
    ["uiDesc", deviceId],
    () => fetchUiDesc(deviceId),
    { enabled }
  ); 
}

export const useUiDataQuery = ({deviceId, jsonkeys, commTech, enabled}) => {
  return useQuery(
    ["uiData", deviceId],
    () => fetchUiData(deviceId, jsonkeys, commTech),
    { enabled }
  );
}


export const usePendingSettingsQuery = ({deviceId, settingsId, enabled}) => {
  return useQuery(
    ["pendingSettings", deviceId],
    () => { 
      if(settingsId == null || isNaN(settingsId)){
        return [];
      }
      // if(settingsId == null || isNaN(settingsId)){
      //   throw Error("fetchpendingSettings: settingsId in incorrect");
      // }
      // if(deviceId == null || isNaN(deviceId)){
      //   throw Error("fetchpendingSettings: deviceId in incorrect");
      // }
      return fetchPendingSettings(deviceId, settingsId);
    },
    { enabled }
  );
}

export const useKeyValueQuery = ({device, uiDesc, enabled}) => {
  return useQuery(
    ["keyValues", device.id],
    () => {
      if(device?.activationTime == null){
        throw Error("Device not activated: key values not fetched");
      }
      const {bucket, idFieldName} = getInfluxDesc(device.commTech);
      const queryParams = {
        bucket,
        idFieldName,
        deviceType: device.type,
        deviceUUID: device.uuid,
        fields:     [...new Set(uiDesc?.map(row => row.influxKey)?.filter(n => n))],
        commTech:   device?.commTech ?? "LTE-M",
      };
      return fetchKeyValues(queryParams)
    },
    { enabled }
  );
}
  