import { withTranslation } from 'react-i18next';
import {default as MuiAlert }from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const defaultStyle = {mt: 1, mb: 1 };

const PureAlert = ({t, type, text, title="error-alert-title"}, sx={}) => {
  if(!text){
    return false;
  }
  return (
    <MuiAlert severity={type ?? "info"} sx={{...defaultStyle, ...sx}}>
      { !!title && <AlertTitle>{t(title)}</AlertTitle> }
      {t(text)}
    </MuiAlert>  
  )
}

const PureAlertError = ({t, text, title="error-alert-title"}, sx={}) => {
  if(!text){
    return false;
  }
  return (
    <MuiAlert severity="error" sx={{...defaultStyle, ...sx}}>
      { !!title && <AlertTitle>{t(title)}</AlertTitle> }
      {t(text)}
    </MuiAlert>  
  )
}

const PureAlertWarning = ({t, text, title="warning-alert-title"}, sx) => {
  if(!text){
    return false;
  }
  return (
    <MuiAlert severity="warning" sx={{...defaultStyle, ...sx}}>
      { !!title && <AlertTitle>{t(title)}</AlertTitle> }
      {t(text)}
    </MuiAlert>
  )
}

const PureAlertInfo = ({t, text, title="info-alert-title"}, sx={}) => {
  if(!text){
    return false;
  }
  return (
    <MuiAlert severity="info" sx={{...defaultStyle, ...sx}}>
      { !!title && <AlertTitle>{t(title)}</AlertTitle> }
      {t(text)}
    </MuiAlert>
  )
}

const PureAlertSuccess = ({t, text, title="success-alert-title"}, sx={}) => {
  if(!text){
    return false;
  }
  return (
    <MuiAlert severity="success" sx={{...defaultStyle, ...sx}}>
      { !!title && <AlertTitle>{t(title)}</AlertTitle> }
      {t(text)}
    </MuiAlert>
  )
}

const Alert = withTranslation()(PureAlert);
const AlertError = withTranslation()(PureAlertError);
const AlertWarning = withTranslation()(PureAlertWarning);
const AlertInfo = withTranslation()(PureAlertInfo);
const AlertSuccess = withTranslation()(PureAlertSuccess);

export {
  Alert,
  AlertError,
  AlertWarning,
  AlertInfo, 
  AlertSuccess
};