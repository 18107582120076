import { configureStore } from "@reduxjs/toolkit";
import deviceReducer from '../data/devicesSlice.js';
import uiReducer from '../data/uiSlice.js';
import locationReducer from "../data/locationsSlice.js";
import alarmReducer from "../data/alarmsSlice.js";

export const store = configureStore({
    reducer: {
        devices:    deviceReducer,
        locations:  locationReducer,
        alarms:     alarmReducer,
        ui:         uiReducer,
    }
})