import MultiguardIcon from "./MultirguardIcon";
import MultiguardLetterIcon from "./MultiguardLetterIcon";
import LeakguardIcon from "./LeakguardIcon";
import LeakguardLetterIcon from "./LeakguardLetterIcon";
import PressureguardIcon from "./PressureguardIcon";
import PressureguardLetterIcon from "./PressureguardLetterIcon";
import SystemSecurityUpdateWarningIcon from '@mui/icons-material/SystemSecurityUpdateWarning';

// An element that renders device type names into correspoding icons. 
// type: type name str, 
// variant: str (null (def) | 'letter')
// props: props of the icon
const DeviceTypeIcon = ({type, variant, ...props}) => {
  switch (type) {
    case "MultiGuard THVCLPS":
    case "MG Olosuhdevahti":
      if(variant === "letter") return <MultiguardLetterIcon {...props} />;
      return <MultiguardIcon {...props} />;

    case "LeakGuard":
      if(variant === "letter") return <LeakguardLetterIcon {...props} />;
      return <LeakguardIcon {...props} />;

    case "PressureGuard":
    case "PressureGuard rev2":
      if(variant === "letter") return <PressureguardLetterIcon {...props} />;
      return <PressureguardIcon {...props} />;
  
    default:
      return <SystemSecurityUpdateWarningIcon {...props} />;
  }
}
export default DeviceTypeIcon;