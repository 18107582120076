import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import CollapseButton from '../CollapseButton';
import { LocationTypeIcon } from '../Icons/LocationTypeIcon';
import DeviceTypeIcon from '../Icons/DeviceTypeIcon';
import DeviceStatusBadge from '../Devices/DeviceStatusBadge';
// Index of the virtual free devices location and a multipler for child row intendion.
import { FREE_DEVICES_LOCATION_ID, OFFSET_MULTIPLER } from './locationTableSettings';

import {
  Badge,
  Box,
  IconButton,
  Tooltip,
  Stack,
} from '@mui/material/';
import { 
  ExpandMore,
} from '@mui/icons-material/';
import {
  gridFilteredDescendantCountLookupSelector,
  useGridSelector,
} from '@mui/x-data-grid';

import { defaultRowBackgroundColor } from './locationTableSettings';

// The function is modified from: https://mui.com/x/react-data-grid/master-detail/
const DetailPanelToggle = ({isExpanded}) => {
  return (
    <IconButton
      size="small"
      tabIndex={-1}
      aria-label={isExpanded ? 'Close' : 'Open'}
    >
      <ExpandMore
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
        }}
        fontSize="inherit"
      />
    </IconButton>
  );
}

// Function for formating the first column of the location table.
// This code is modified version of: 
// https://github.com/mui/mui-x/packages/x-data-grid-pro/src/components/GridTreeDataGroupingCell.tsx
const RawLocationTableGroupingColumnDef = ({ t, id, field, rowNode, row, apiRef }) => {
  const isFreeDevicesRow = id === FREE_DEVICES_LOCATION_ID;
  const isDeviceRow = row?.isDeviceRow ?? false;
  const [detailPanelIsExpanded, setDetailPanelIsExpanded] = useState(apiRef.current.getExpandedDetailPanels().includes(id));

  let shownValue = '';
  if(row.name === undefined){
    shownValue = '?'
  }
  else if(row.name === 'free-devices'){
    shownValue = t('free-devices');
  }
  else{
    shownValue = row.name;
  }  

  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector,
  );
  const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;
  const rowDoenstHaveChildren = filteredDescendantCount === 0;

  const handleExpandChildrenClick = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation(); //
  };

  // Clicking free devices row shows the free devices; 
  // Clicking other rows opens a detail panel.
  const handleNameClick = (event) => {
    if(isFreeDevicesRow){
      handleExpandChildrenClick(event);
    }
    else{
      setDetailPanelIsExpanded(!detailPanelIsExpanded)
      apiRef.current.toggleDetailPanel(id);
    }
    event.stopPropagation();
  }

  // Close the detail panel if the row is expanded
  // A BUG: if child row has panel open, it will be closed, but the detailPanelIsExpanded state won't get updated
  // source: https://github.com/mui/mui-x/issues/12088
  useEffect(() => {
    const handleRowExpansionChange =
      async (node) => {
        setDetailPanelIsExpanded(false);
        const expandedPanels = apiRef.current.getExpandedDetailPanels();
        apiRef.current.setExpandedDetailPanels(expandedPanels.filter( id => id !== node.id ));
        return;
      };

    return apiRef.current.subscribeEvent(
      'rowExpansionChange',
      handleRowExpansionChange
    );
  }, [apiRef]);

  // Device Row
  if(isDeviceRow){
    return (
      <Stack 
        direction="row"
        onClick={handleNameClick}
        sx={{ 
          height:'36px',
          borderTop: "1px solid",
          borderBottom: "1px solid",
          borderLeft: "1px solid",
          borderColor: theme => theme.palette.main,
          borderRadius: "10px 0 0 10px", 
          mr: -2, 
          ml: rowNode.depth * OFFSET_MULTIPLER 
          }}
      >
        <Box sx={{mb: -0.7, mx: 1}}>
          <DeviceStatusBadge status={row?.deviceStatus}>
            <DeviceTypeIcon type={row.type}  />
          </DeviceStatusBadge>
        </Box>
        <Tooltip title={shownValue} >
          {shownValue}
        </Tooltip>
        <DetailPanelToggle isExpanded={detailPanelIsExpanded} />
      </Stack>
    );
  }
  // Location Row
  return (
    <Stack 
      direction="row" 
      spacing={0.5} 
      sx={{
        height:'36px',
        borderRadius: "10px 0 0 10px", 
        mr: -2, 
        ml: rowNode.depth * OFFSET_MULTIPLER, 
        backgroundColor: defaultRowBackgroundColor 
        }}>
      <CollapseButton 
        type="tree"
        open={rowNode.childrenExpanded} 
        onClick={handleExpandChildrenClick}
        hide={rowDoenstHaveChildren}
      />
      <Box onClick={handleNameClick}>
        <LocationTypeIcon type={row.type} sx={{mb: -0.5, mr: 0.5}} />
        <Tooltip title={shownValue} >
          {shownValue}
        </Tooltip>
        { !isFreeDevicesRow && <DetailPanelToggle isExpanded={detailPanelIsExpanded} /> }
      </Box>
      </Stack>
  );
}
const LocationTableGroupingColumnDef = withTranslation()(RawLocationTableGroupingColumnDef);
export default LocationTableGroupingColumnDef;