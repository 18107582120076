import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import { 
  updateDeviceLocation,
  sendNewDeviceName,
} from '../../server/serverOperation'

import { 
  selectDeviceById,
  deviceNameSet 
} from '../../data/devicesSlice';
import { selectCurrentProjectId, timeRangeSet, currentDeviceSet, brandSet, themeModeSet, currentPageSet } from '../../data/uiSlice';
import { 
  deviceMoved,
  selectLocationByDeviceId, 
} from '../../data/locationsSlice';
import { checkMode } from '../../theme';

import logger from '../../utility/logger.mjs';
// multipler for child row intendion 
import { OFFSET_MULTIPLER } from '../Locations/locationTableSettings';

import DeviceStatusBadge from './DeviceStatusBadge';
import { DataList } from '../DataList/DataList';
import { ButtonSmall } from '../Button';
import { RawLocationSelect } from '../Locations/LocationSelect';
import { AlertWarning } from '../Alert';
import { PrettyTime, TimeLeftTo, Timezone } from '../PrettyTime';
import DeviceActionButtons from './DeviceActionButtons';

import {
  Box,
  Card,
  CardContent,
  Input,
  Stack,
  Typography, 
} from '@mui/material/';
import DeviceTypeIcon from '../Icons/DeviceTypeIcon';
import DeviceStatusIcon from '../Icons/DeviceStatusIcon';

// COMPONENT: Detail panel that has info about given device.
const PureDeviceDetails = ({t, deviceId, isInLocationTable, tableCard, tableApiRef, hideActionButtons}) => {
  const dispatch = useDispatch();

  const rowId = `d${deviceId}`;
  const device = useSelector(selectDeviceById(deviceId));
  const locationId = useSelector(selectLocationByDeviceId(deviceId))?.id ?? -1;
  const projectId = useSelector(selectCurrentProjectId);

  const [newDeviceName, setNewDeviceName] = useState(device.name);
  const [newLocationId, setNewLocationId] = useState(locationId);

  const { mutate:mutateDeviceLocation, isLoading:mutateDeviceLocationisLoading } = useMutation(
    updateDeviceLocation, // parameter {deviceId, locationId, projectId}
    {
      onSuccess: async () => {
        // update redux state
        dispatch(deviceMoved({
          locationId: newLocationId, 
          deviceId,
          projectId,
        }));
        if(isInLocationTable){
          // Construct path to the device and update the data grid row
          let path = [];
          if(newLocationId > 0){
            path = tableApiRef.current.getRow(newLocationId).path.concat([rowId]);
          }
          else{ // the target location is moved to the top
            path = [rowId];
          }
          tableApiRef.current.updateRows([{id:rowId, path}]);
        }
        toast.success(t("device-moved-info"));
      },
      onError: (error) => {
        toast.error(error)
        logger.error(error);
      }
    }
  );

  const { mutate:mutateDeviceName, isLoading:mutateDeviceNameIsLoading } = useMutation(
    sendNewDeviceName, // parameter {deviceId, newName}
    {
      onSuccess: async (data) => {
        if(data.ok){
          // update redux state
          dispatch(deviceNameSet({deviceId, newName: newDeviceName}));
          if(isInLocationTable){
            // update data grid row
            tableApiRef.current.updateRows([{id: rowId, name: newDeviceName}]);
          }
          toast.success(t("device-name-updated-info"));
        }
        else{
          toast.error(t("device-name-not-updated-error"));
        }
      },
      onError: (error) => {
        toast.error(t("device-name-not-updated-error"));
        logger.error(error);
      }
    }
  );

  const handleDeviceSaveClick = () => {
    if(newDeviceName.length > 0){
      if(newDeviceName !== device.name){
        mutateDeviceName({deviceId, newName: newDeviceName});
      }
      if(newLocationId !== locationId){
        mutateDeviceLocation({deviceId, locationId: newLocationId, projectId})
      }
    }
    else {
      toast.warning(t("please-give-a-name"));
    }
  }

  const handleResetClick = () => {
    setNewDeviceName(device.name);
    setNewLocationId(locationId);
  }

  const isLoading = mutateDeviceNameIsLoading || mutateDeviceLocationisLoading;
  const disabled = isLoading;

  const timeLeft = device?.activationTime 
    ? <TimeLeftTo duration={{years: 2}}>{device.activationTime}</TimeLeftTo>
    : "?";
  const activationTime = device?.activationTime
    ? <PrettyTime format={"DATE_SHORT"}>{device.activationTime}</PrettyTime> 
    : "?";

  // Contents of a list of information about the device.
  // Array of rows with arrays of a header and conents.
  let deviceDataForList = [
    [
      t("name"), 
      <Input 
        sx={{width:"100%"}}
        multiline
        maxRows={8}
        id="TextField" 
        type="text" 
        value={newDeviceName}
        disabled={disabled}
        onChange={e => setNewDeviceName(e.target.value)}
      />
    ],
    [
      t("type"), 
      <Stack direction="row" spacing={1} sx={{alignItems: "center",}}>
        <div>{device.type}</div>
        <DeviceTypeIcon type={device.type} />
      </Stack>
    ],
    [
      t("status"),
      <Stack 
        spacing={1}
        direction="row"
      >
        <div>{device?.deviceStatus ?? t("unknown")}</div>
        <DeviceStatusIcon status={device?.deviceStatus} tooltipDisabled />
      </Stack>
    ],
    [
      t("uuid"), 
      device.uuid
    ],
    [
      t("activated"), 
      activationTime
    ],
    [
      t("time-left"), 
      timeLeft
    ],
    [
      t("timezone"), 
      <Timezone offset={device?.offset}/>
    ],
    [
      t("your-privileges"), 
      t(device.privileges)
    ],
    [
      t("position"),
      <RawLocationSelect 
        value={newLocationId} 
        onChange={e => setNewLocationId(e.target.value)} 
        deviceVariant 
        />
    ]
  ]; 

  return (
    <Box sx={{width: '350px',}}>
      <Card variant="outlined" >
        <CardContent>
          <Stack 
            direction="row" 
            spacing={1} 
            sx={{
              mx:1,
              justifyContent: "space-between",
              alignItems: "center",
              }}
            >
            <Typography gutterBottom variant="h2" component="div">
              {t('device')}
            </Typography>
            <DeviceActionButtons deviceId={deviceId} hide={hideActionButtons} />
        </Stack>
        <DataList 
              data={deviceDataForList}
              key={deviceId} 
            />
        </CardContent>
        <Stack direction="row" sx={{mb: 1, mt: -1, justifyContent: "space-around",}}>
          <ButtonSmall 
            sx={{m: 0}}
            text="clear" 
            onClick={handleResetClick} 
            disable={isLoading}
            />
          <ButtonSmall 
            sx={{m: 0}}
            text="save" 
            onClick={handleDeviceSaveClick}
            disable={isLoading}
            />
        </Stack>
      </Card>
    </Box>
  )
}
const DeviceDetails = withTranslation()(PureDeviceDetails);

export default DeviceDetails;