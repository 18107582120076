import { useSelector } from "react-redux";

import {
  LIMIT_TYPE,
} from '../utility/influxChartConfig.mjs';

import { selectCurrentDevice } from "../data/devicesSlice";
import { selectCurrentPage } from "../data/uiSlice";

const prettyNumberNullValue = (value) => {
  if(typeof value == "number" && (value < -1000 || value > 1000)) {
    return null;
  }
  return value;
}

export const useGetChartDesc = () => {
  const pagename = useSelector(selectCurrentPage);
  const device = useSelector(selectCurrentDevice);

  const fields = [... new Set(
    device.uiDesc
      .filter( row => row.uiPage === pagename )
      .toSorted( (a,b) => a.weight - b.weight )
      .map( row => row.influxKey ))
  ];
  const categoryName = fields.reduce( (acc, cur) => ({...acc, [cur]: (device.uiDesc.find( row => row.influxKey === cur).category)}),{})
  const fieldSettings = fields
    .reduce( (acc, cur) => ({...acc, [cur]: (device.uiDesc.filter( row => row.uiPage === pagename ).find( row => row.influxKey === cur).description)}),{})

  let alarmLimitTypes = {};
  let referenceValues = {};
  let alarmLimit = {};

  if(device.commTech === "LTE-M"){
    const jsonValInd = Object.fromEntries([... new Set(
      device.uiDesc
        .filter( row => row.uiPage === pagename )
        .map( row => [row.influxKey, row.JSONValInd] ))
    ]);
    // alarm limit types for fields
    alarmLimitTypes = fields.reduce( (a, field) => {
      return {...a, [field]: device?.newSettings?.sAtyp?.[jsonValInd[field]]}
    }, {});

    // show reference values for a field only if alarm type is relative
    referenceValues = fields.reduce( (a, field) => {
      if( alarmLimitTypes[field] === LIMIT_TYPE["relative"] 
          && device.data?.referenceValues?.[field] != null         ){
            return {...a, [field]: device.data.referenceValues[field]}
      }
      return a;
    }, {});

    // select alarmlimit on bases of alarmLimitTypes
    alarmLimit = fields.reduce( (a, field) => {
      let limits = {low: null, high: null};
      if(alarmLimitTypes[field] === LIMIT_TYPE["relative"]){
        const referenceValue = referenceValues[field];
        const low = referenceValue - (referenceValue * (prettyNumberNullValue(device?.newSettings?.sAmad[jsonValInd[field]]) / 100));
        const high = referenceValue + (referenceValue * (prettyNumberNullValue(device?.newSettings?.sAmao[jsonValInd[field]]) / 100));
        limits = { low, high };
      } else {
        limits = {
          low: prettyNumberNullValue(device?.newSettings?.sAllt?.[jsonValInd?.[field]]), 
          high: prettyNumberNullValue(device?.newSettings?.sAhlt?.[jsonValInd?.[field]])
        }
      }
      return ({
        ...a, 
        [field]: limits
      })
    }, {}); 
  }
  if(device.commTech === "Sigfox"){
    alarmLimit = device.uiDesc
      .filter( row => row.uiPage === pagename && row.description?.alarmLimits != null )
      .reduce( (acc,cur) => {
        const lowLimitEnabled = device.newSettings?.[cur.description.alarmLimits?.lowEnableSrc];
        const highLimitEnabled = device.newSettings?.[cur.description.alarmLimits?.highEnableSrc];
        const low = lowLimitEnabled ? prettyNumberNullValue(device.newSettings?.[cur.description.alarmLimits?.lowSrc]) : null;
        const high = highLimitEnabled ? prettyNumberNullValue(device.newSettings?.[cur.description.alarmLimits?.highSrc]) : null
        return ({
          ...acc,
          [cur.category]: {
            low,
            high
          }
        })
      },{})
  }
  return {
    fields,
    fieldSettings,
    categoryName,
    alarmLimitTypes,
    referenceValues,
    alarmLimit
  }
}