import { withTranslation } from 'react-i18next';

import PhonenumberEditDialog from './PhonenumberEditDialog.js';
import PhonenumberTable from './PhonenumberTable.js';

import { Box, Link, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectPrivileges } from '../../data/devicesSlice.js';

const TELEGRAM_BOT_URL = process.env.REACT_APP_TELEGRAM_BOT_URL;

const PurePhonenumbers = ({t}) => {
  const privileges = useSelector(selectPrivileges);

  const InfoText = () => {
    return (
      <p >
        {t("phonenumbers-info")}<Link href={TELEGRAM_BOT_URL + '?start'} target="_blank">{t("with-telegram-bot")}</Link>.
      </p>
    )  
  }
  return (
    <Box sx={{textAlign: 'left', mb: 5}}>
      <Typography sx={{textAlign: 'left'}} variant="h2">{t('phonenumbers')}</Typography>
      <InfoText />
      { ['superadmin', 'admin'].includes(privileges) && 
        <PhonenumberEditDialog /> 
      }
      <PhonenumberTable editDisabled showSelfDelete/>
    </Box>
  )      
}
const Phonenumbers = withTranslation()(PurePhonenumbers);
export default Phonenumbers