import { lighten } from "@mui/material";

// Default color for row backgrounds
export const defaultRowBackgroundColor = (theme) => lighten(theme.palette.info.main, .8);

// Multipler for intendion of child rows.
export const OFFSET_MULTIPLER = 1.5;

// Virtual top location index
export const TOP_LOCATION_IND = -1;

// Virtual free devices location index
export const FREE_DEVICES_LOCATION_ID = -2;