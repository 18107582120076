import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";

import { updateHourCycle } from "../server/serverOperation.js";
import useParamSelector from '../data/useParamSelector.js'
import { 
  selectCurrentComponentDesc, 
} from '../data/devicesSlice.js';
import { 
  selectUserPhoneId,
  selectHourCycle,
  hourCycleSet,
} from "../data/uiSlice.js";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { withTranslation } from 'react-i18next';

function PureHourCycleButtons({ t, block }) {
  const dispatch = useDispatch();
  const disabled = block.disabled ?? false;
  const componentId = block.id;
  const userPhoneId = useSelector(selectUserPhoneId);
  const data = useParamSelector(selectCurrentComponentDesc, componentId);
  const desc = data.description;
  const labels = desc?.LABELS.map( l => l[0]);
  const values = desc?.LABELS.map( l => l[1]);
  const title = desc?.TITLE;
  const currentHourCycle = useSelector(selectHourCycle);
  const buttonValue = values.indexOf(currentHourCycle);

  const handleChange = (event, newIndex) => {
    const hourCycle = values[newIndex];
    dispatch(hourCycleSet(hourCycle));
    mutate({userPhoneId, hourCycle});
  };

  const { mutate, isLoading, error } = useMutation(
    updateHourCycle, // userPhoneId, newUnitSystem}
    {
      // onSuccess: async (data) => {
      //   console.log(data);
      // },
      onError: (error) => {
        console.log(error);
      }
    }
  );

  return (
    <FormControl style={{textAlign: "left", marginLeft: "1em"}}>
      <FormLabel>{t(title)}</FormLabel>
      <RadioGroup
        row
        name="row-radio-buttons-group"
        value={buttonValue}
        onChange={handleChange}
      >
        {labels.map ( (label, i) =>
          <FormControlLabel key={label} value={i} control={<Radio disabled={disabled}/>} label={t(label)} />
        )}
      </RadioGroup>
    </FormControl>
  );
};
const HourCycleButtons = withTranslation()(PureHourCycleButtons);

function PureHourCycleButtonsForMenu({ t }) {
  const dispatch = useDispatch();
  const userPhoneId = useSelector(selectUserPhoneId);
  const buttonValue = useSelector(selectHourCycle);
  const test = useSelector(selectHourCycle);

  const handleChange = (event, newValue) => {
    dispatch(hourCycleSet(newValue));
    mutate({userPhoneId, newValue});
  };

  const { mutate, isLoading, error } = useMutation(
    updateHourCycle, // userPhoneId, newUnitSystem}
    {
      // onSuccess: async (data) => {
      //   console.log(data);
      // },
      onError: (error) => {
        console.log(error);
      }
    }
  );

  return (
    <FormControl style={{textAlign: "left", marginLeft: "1em"}}>
      <FormLabel>{t("hour-cycle")}</FormLabel>
      <RadioGroup
        row
        name="row-radio-buttons-group"
        value={buttonValue}
        onChange={handleChange}
      >
          <FormControlLabel key={"24h"} value={"h23"} control={<Radio />} label={t("24h")} />
          <FormControlLabel key={"12h"} value={"h12"} control={<Radio />} label={t("h23")} />
      </RadioGroup>
    </FormControl>
  );
};
const HourCycleButtonsForMenu = withTranslation()(PureHourCycleButtonsForMenu);

export {
  HourCycleButtons,
  HourCycleButtonsForMenu,
};