import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';


import { RawDialog } from "../InfoDialog.js";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import useConfirm from "../ConfirmDialog";

import { 
  countryToLanguage,
  countryToUnitSystem,
  countryToHourCycle
} from "../../utility/userConfig.mjs";
import { activateDevices } from '../../server/serverOperation.js';
import { selectAllDevices, selectCurrentPhonenumbers } from '../../data/devicesSlice.js';
import { selectLanguage, selectCurrentDeviceId } from "../../data/uiSlice.js";
import PhonenumberInput from "../Phonenumbers/PhonenumberInput.js";
import { ButtonWide, ButtonSmall } from "../Button.js";
import { HelpText } from '../ReadMore.js';
import { AlertInfo } from '../Alert.js';
import { fiFI } from '@mui/x-data-grid/locales';

import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material/';
import TelegramIcon from '@mui/icons-material/Telegram';
import CloseIcon from '@mui/icons-material/Close';
import { Add } from "@mui/icons-material";
import useDataGridLocaleText from "../Locations/useDataGridLocaleText.js";
import DeviceTypeIcon from "../Icons/DeviceTypeIcon.js";


const localeText = {
  en: null,
  fi: fiFI.components.MuiDataGrid.defaultProps.localeText,
}
const userTypes = ['superadmin']

const TELEGRAM_BOT_URL = process.env.REACT_APP_TELEGRAM_BOT_URL;



const columns = [
  {
    field: 'type',
    headerName: '', //t('type'),
    width: 40,
    display: 'flex',
    align: 'center',
    // render device type as icons
    renderCell: row =>  (// TODO drop tooltip here
      <Tooltip title={row.value}>
        <div>
          <DeviceTypeIcon type={row.value} />
        </div>
      </Tooltip>
      ),
  },
  {
    field: 'name',
    headerName: 'name',
    flex: 1,
    editable: true,
  },
  {
    field: 'uuid',
    headerName: 'id',
    width: 150,
    editable: false,
  },
  // {
  //   field: 'fullName',
  //   headerName: 'Full name',
  //   description: 'This column has a value getter and is not sortable.',
  //   sortable: false,
  //   width: 160,
  //   valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
  // },
];

const rows = [
  { id: 1, type: 'MultiGuard', name: '002291FB',   },
  { id: 2, type: 'MultiGuard', name: '0037ED81',   },
  { id: 3, type: 'LeakGuard', name:'LG_36A930',   },
  { id: 4, type: 'LeakGuard', name:'LG_38BC3E', },
  
];

const PureActivateDevicesDialog = ({t, openButtonType}) => {
    const language = useSelector(selectLanguage);
    const [newNumber, setNewNumber] = useState({number: "", country: ""});
    //const phonenumbers = useSelector(selectCurrentPhonenumbers) || [];
    //const initialOpen = !phonenumbers?.some(num => num.userType === "superadmin" && !!num.telegramId );
    const [openFirst, setOpenFirst] = useState(false);
    const [openSecond, setOpenSecond] = useState(false);
    const [pin, setPin] = useState("");
    const [pinIsOk, setPinIsOk] = useState(false);
    const [pinInputColor, setPinInputColor] = useState("grey");
    const devices = useSelector(selectAllDevices).filter(device => !device.inUse);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [devicesIdsForActivation, setDevicesIdsForActivation] = useState([]);

    useEffect((devicesIdsForActivation) => {
      setDevicesIdsForActivation(devices.filter( d => selectedDevices.includes(d.id) ))
    }, [selectedDevices])

    const handlePinChange = (e) => {
      const valueIsValid = 
          e.target.value === ""
        || (/^[0-9]+$/.test(e.target.value) && e.target.value.length <= 6)
      if (valueIsValid){
        setPin(e.target.value);
      }
    }

    useEffect(() => {
      setPinIsOk(pin.length === 6);
      setPinInputColor(
        pin.length === 6 
          ? "green"
          : pin.length === 0
            ? "grey"
            : "red"
      );
    }, [pin])
    
    const handleNextClick = () => {
      const phonenumber = newNumber.number;
      const country = newNumber.country;
      const numberOk = phonenumber.length > 0 && country.length > 0;
      // if(numberOk && pinIsOk){
      //   mutateUpdateSuperadmin({ 
      //     deviceId, 
      //     phonenumber, 
      //     country, 
      //     unitSystem: countryToUnitSystem(country),
      //     language: countryToLanguage(country),
      //     hourCycle: countryToHourCycle(country),
      //   });
      //   mutatePin({ 
      //     deviceId, 
      //     newPin: pin 
      //   });
      // }
      if(!numberOk){
        toast.error(t("setup-number-error"), {
          toastId: "setup-number-error"
        });
      }
      if(!pinIsOk){
        toast.error(t("pin-error"), {
          toastId: "pin-error"
        });
      }
      if(selectedDevices.length == 0){
        toast.error(t("No devices selected!"), {
          toastId: "devices-not-selected-error"
        });
      }
      if(numberOk && pinIsOk && selectedDevices.length > 0){
        //setOpenFirst(false);
        setOpenSecond(true);
      }
      
    }

    const { mutate:mutateActivateDevices, isLoading:updateSuperadminIsLoading, isSuccess:updateSuperadminIsSuccess } = useMutation(
      activateDevices, // parameter {[devIds], phonenumber, country, language, pinCode:newPin}
    );

    const handleClickOpen = () => {
      setOpenFirst(true);
      setOpenSecond(false);
    }

    const handleClose = () => {
      setOpenFirst(false);
      setOpenSecond(false);
    }
    
    const handleBackClick = () => {
      setOpenFirst(true);
      setOpenSecond(false);
    }

    const OpenButton = ({type}) => {
      if(type === "plus"){
        return(
          <IconButton onClick={handleClickOpen}>
            <Add />
          </IconButton>
        )
      }
      return (
        <Button variant="outlined" onClick={handleClickOpen}>
          {t('activate-devices-dialog-button')}
        </Button>
      )
    }

    return (
      <>
        <OpenButton type={openButtonType} />
        <Dialog 
          fullScreen
          open={openFirst}
          onClose={handleClose}
        >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t("activate-devices")}
            </Typography>
          </Toolbar>
        </AppBar>
        {/* <DialogTitle color="secondary.main">
          <Box sx={{maxWidth: "70%"}}>
            {t("setup-dialog-title")} 1/2
          </Box>
          <Box sx={{position: 'absolute',
            right: 10,
            top: 13,}}>
            <LangaugeSelector />
          </Box>
        </DialogTitle> */}
          <DialogContent >
            {t("activate-devices-dialog-info-1")}
            <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("phonenumber")}</Typography>
            <PhonenumberInput 
              number={newNumber.number} 
              setNewNumber={ setNewNumber } 
              useType={userTypes[0]} 
              setUserType={() => {}} 
              userTypes={userTypes} 
              inDialog
              defalutCountry={language}
              />
            <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("pin-code")}</Typography>
            <p style={{maxWidth: "90%"}}>{t("setup-dialog-pin-text")}</p>
            <HelpText 
                text={t("setup-dialog-info-2-more")} 
                buttonStyle={{float:"right", marginTop: -65}}
                />
            <TextField
              id="newPin" 
              sx={{ input: { color: pinInputColor }} }
              type="text" 
              name="newPint"
              placeholder={t("create-new-pin")}
              value={pin}
              onChange={ handlePinChange }
              size="small"
            />
            <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("unactivated-devices")}</Typography>
            <DataGridPro
              localeText={useDataGridLocaleText()}
              rows={devices}
              columns={columns}
              density='compact'
              pageSizeOptions={[5,10,20,50]}
              checkboxSelection
              disableRowSelectionOnClick
              onRowSelectionModelChange={(newSet) => {
                setSelectedDevices(newSet);
              }}
              rowSelectionModel={selectedDevices}
            />
          </DialogContent>
          <DialogActions>
            <ButtonSmall
              width="26%" 
              text="cancel" 
              onClick={ handleClose } 
              isLoading = { updateSuperadminIsLoading }
            />
            <ButtonSmall
              width="26%" 
              text="next" 
              onClick={ handleNextClick } 
              isLoading = { updateSuperadminIsLoading }
            />
          </DialogActions>
        </Dialog>

        <Dialog 
          open={openSecond}
          onClose={ () => setOpenSecond(true) }
        >
          <DialogTitle color="secondary.main">
            {t("activate-devices")}
          </DialogTitle>
          <DialogContent>  
            <p>{t("activate-devices-dialog-2-info-1")}</p>
            <DataGridPro
              localeText={useDataGridLocaleText()}
              rowHeight={38}
              columns={columns}
              rows={devicesIdsForActivation} 
              hideFooter
              columnHeaderHeight={0}
            />
            <p>{t("phonenumber")}: <b>{newNumber.number}</b> </p>
            <p>{t("activate-devices-dialog-2-info-2")}</p>
          </DialogContent>  
          <DialogActions>
            <ButtonSmall
              margin={"-15px 10px 20px 0px"}
              width="26%" 
              text="cancel" 
              onClick={ handleBackClick } 
            />
              <ButtonSmall
              margin={"-15px 10px 20px 0px"}
              width="26%" 
              text="ok" 
              onClick={ handleBackClick } 
            />
          </DialogActions>
        </Dialog>
      </>
    );
}

/****************************** */

// const PureActivateDevicesDialog1 = ({t}) => {
//   const language = useSelector(selectLanguage);
//   const [newNumber, setNewNumber] = useState({number: "", country: ""});

//   const confirm = useConfirm();

//   const handleActivateClick = async (dialogCloseFunc) => {
//     const pinCodeIsOk = false;
//     const phonenumberIsOk = false;
//     if(!pinCodeIsOk){
//       alert = <AlertError text={"no-superadmin-confirm-warning"} title={"warning-deleting-superadmin-title"} />
//     }
//     else if(!phonenumberIsOk) {
//       alert = <AlertError text={"warning-deleting-oneself"} title={"warning-deleting-oneself-title"} />
//     }
//     // downgrading one self
//     else if(downgradingOneSelf) {
//       alert = <AlertError text={"warning-losing-admin-privileges"} title={"warning-losing-admin-privileges-title"} />
//     }
    
//     if(!alert){ // everything ok!
//       await handleSaveOk(dialogCloseFunc);
//       return;
//     }

//     const confirmationOk = await confirm({
//       title: "warning",
//       children: alert,
//       okButtonText: "ok",
//       cancelButtonText: "cancel"
//     });

//     if(confirmationOk){
//       if(noSuperadmin){
//         executeDropAllNumbers(deviceId);
//         logout();
//         return;
//       }
//       if(deletingOneSelf){
//         await handleSaveOk(dialogCloseFunc);
//         logout();
//         return;
//       }
//       if(downgradingOneSelf){
//         await handleSaveOk(dialogCloseFunc);
//         return;
//         //dispatch(currentDeviceSet(8));
//       }
//     } 
//   }
    
//   return (
//     <>
//       <RawDialog
//         data={{
//           title: <>{t("Activate devices")} </>,
//           openButtonText: "activate devices",
//           okButtonText: "activate",
//           okButtonFunc: handleActivateClick,
//           //okButtonIsLoading: mutateLocationsIsLoading,
//           //okButtonDisabled: name.length === 0,
//           cancelButtonText: "close",
//           //cancelButtonFunc: () => setOpen(false),
//           //cancelButtonIsLoading,
//           }}
//         >
//         {t("activate-devices-dialog-info-1")}
//           <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("phonenumber")}</Typography>
//           <PhonenumberInput 
//             number={newNumber.number} 
//             setNewNumber={ setNewNumber } 
//             useType={userTypes[0]} 
//             setUserType={() => {}} 
//             userTypes={userTypes} 
//             inDialog
//             disableUserTypeSelect
//             defalutCountry={language}
//             />
//           <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("pin-code")}</Typography>
//           <p style={{maxWidth: "90%"}}>{t("setup-dialog-pin-text")}</p>
//           <HelpText 
//               text={t("setup-dialog-info-2-more")} 
//               buttonStyle={{float:"right", marginTop: -65}}
//               />
//           <TextField
//             id="newPin" 
//             //sx={{ input: { color: pinInputColor }} }
//             type="text" 
//             name="newPint"
//             placeholder={t("create-new-pin")}
//             //value={pin}
//             //onChange={ handlePinChange }
//             size="small"
//           />
//         <Box sx={{ height: 400, width: '100%' }}>
//         <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t("unactivive-devices")}</Typography>
//       <DataGrid
//         rows={rows}
//         columns={columns}
//         //pageSizeOptions={[5]}
//         checkboxSelection
//         disableRowSelectionOnClick
//       />
//     </Box>
//       </RawDialog>
//     </>
//   )
// }
const ActivateDevicesDialog = withTranslation()(PureActivateDevicesDialog);
export { 
  ActivateDevicesDialog 
};