import { useState } from "react";
import { withTranslation } from 'react-i18next';

import { AlarmIcon } from "../Icons/AlarmIcon.js";

import { 
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Table,
  TableCell,
  TableRow, 
} from "@mui/material";

const PureAlarmTypesInfoTable = ({t}) => {
  const alarmTypes = [
    "temperature",
    "pressure",
    "psuMonitorAD-off",
    "humidity",
    "leakSensorADValue",
    "PIR",
    "SPL"
  ];
  return (
    <Table width={270} size="small" aria-label="location edit dialog table" >
      {alarmTypes.map( type => {
        return (
          <TableRow key="type">
            <TableCell sx={{}}>
              <AlarmIcon type={type} />
            </TableCell>
            <TableCell>
              {t(type)}
            </TableCell>
          </TableRow>
        )
      })}
    </Table>
  )
}
const AlarmTypesInfoTable = withTranslation()(PureAlarmTypesInfoTable);

const PureAlarmTypesInfoDialog = ({t}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button autoFocus color="inherit" onClick={() => setDialogOpen(true)}>
        ?
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        >
        <DialogTitle>
          {t("alarm-types")}
        </DialogTitle>
        <AlarmTypesInfoTable />
      </Dialog>
    </>
  )
}
const AlarmTypesInfoDialog = withTranslation()(PureAlarmTypesInfoDialog);
export { 
  AlarmTypesInfoDialog,
  AlarmTypesInfoTable,
};