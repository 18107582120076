import { withTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

import logger from '../utility/logger.mjs';
import { unit } from '../utility/influxChartConfig.mjs';
import useParamSelector from '../data/useParamSelector.js'
import { 
  selectCurrentComponentDesc,
  selectCurrentData,
} from '../data/devicesSlice.js';
import { 
  selectUnitSystem 
} from "../data/uiSlice.js";

import { Typography } from '@mui/material';


const PureInfoBox = ({t, block}) => {
  const componentId = block.id;
  const data = useParamSelector(selectCurrentComponentDesc, componentId);
  const desc = data.description;
  const referencevalue = useSelector(selectCurrentData)?.referenceValues?.[data?.category];  // TODO: tee yleiseksi
  //logger.info("data",data, "referencevalue", referencevalue, "data?.category", data?.category);
  const unitSystem = useSelector(selectUnitSystem);
  if(desc.TEXT === "reference-value"){
    let convergedReferencevalue = referencevalue != null
      ? unit[unitSystem][data?.category].conv(referencevalue)
      : "--";
    return (
      <div  style={{ clear: "both", textAlign: "center" }}>
        {`${t(desc?.TEXT)}: ${convergedReferencevalue} ${unit[unitSystem][data.category].symbol}`}
      </div>
    );
  }
  const tagType = desc?.TAGTYPE ?? "div";

  // <TagType  style={{ textAlign:"left", marginLeft:12 }}>
  //     {t(desc?.TEXT)}
  //     </TagType>

  return (
    <Typography 
      variant={tagType}
      style={{ textAlign:"left", marginLeft:12 }}
      >
      {t(desc?.TEXT)}
    </Typography>
  )
};
const InfoBox = withTranslation()(PureInfoBox);

export { InfoBox };