import { useState, useRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
} from '@mui/material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AddIcon from '@mui/icons-material/Add';
import EditButton from '@mui/icons-material/Edit';

import { ButtonSmall } from './Button.js';
import { Alert } from './Alert.js';

const parseMessages = (messages) => {
  if(messages == null){
    return []
  }
  const keys = Object.keys(messages);
  if(keys == null || keys.length === 0){
    return []
  }
  return keys.map( msg => {
    if (messages[msg].active){
      return <Alert type={messages[msg].type} text={messages[msg].text} title={false} />
    }
  })
}

const parseData = (t, data, setOpen) => {
  const closeFunc = () => setOpen(false);
  return ({    
    title:                  (typeof data?.title === 'string') ? t(data?.title) : data?.title,
    hideOpenButton:         !!data?.hideOpenButton,
    openButtonType:         data.openButtonType ?? "button",
    openButtonColor:        data.openButtonColor,
    openButtonText:         t(data.openButtonText ?? "open"),
    openButtonFunc:         data.openButtonFunc ?? (() => setOpen(true)),
    openButtonDisabled:     data.openButtonDisabled,
    okButtonText:           t(data.okButtonText?? t("ok")),
    okButtonFunc:           !!data?.okButtonFunc ? async () => data.okButtonFunc(closeFunc) : closeFunc,
    okButtonIsLoading:      data?.okButtonIsLoading,
    okButtonDisabled:       data?.okButtonDisabled ?? false,
    cancelButtonText:       t(data.cancelButtonText ?? ""),
    cancelButtonFunc:       !!data?.cancelButtonFunc ? async () => data.cancelButtonFunc(closeFunc) : closeFunc,
    cancelButtonIsLoading:  data?.cancelButtonIsLoading,
    extraButtonText:        t(data.extraButtonText ?? ""),
    extraButtonFunc:        data?.extraButtonFunc ?? (() => {}),
    extraButtonIsLoading:   data?.extraButtonIsLoading,
    alertMessages:          parseMessages(data?.alertMessages),
  })
}

const OpenButton = ({buttonText, openButtonType, openButtonColor, sx, openFunc, disabled}) => {
  const handleClick = (event) => {
    openFunc(); 
    event.preventDefault();
  }
  switch (openButtonType) {
    case "footerLink":
      return (
        <Link 
          disabled={disabled}
          color="white" 
          underline="hover" 
          sx={{'font-weight': 'normal', ...sx}} 
          onClick={handleClick} 
          href="#"
          >
            {buttonText}
          </Link>
      ) 
      
    case "questionMark":
      return (
          <IconButton 
            disabled={disabled}
            onClick={openFunc}
            aria-label="show more"
            sx={sx}
            >
            <QuestionMarkIcon />
          </IconButton>
        )

    case "plusIcon":
      return (
          <IconButton 
            disabled={disabled}
            onClick={openFunc}
            aria-label="show more"
            sx={sx}
            color={openButtonColor}
            >
            <AddIcon />
          </IconButton>
        )

    case "editIcon":
      return (
          <IconButton 
            disabled={disabled}
            onClick={openFunc}
            aria-label="show more"
            sx={sx}
            color={openButtonColor}
            >
            <EditButton />
          </IconButton>
        )

    case "ButtonSmall":
    default:
      return (
        <ButtonSmall 
          disabled={disabled}
          onClick={openFunc}
          text={buttonText}
          sx={sx}
        />
      )
  }
}

const AlertMessages = ({messages}) => {
  return (
    <Box style={{display: 'flex', flexWrap: 'wrap', width: "70%"}}>
      { messages }
    </Box>
  )
}

const PureRawDialog = ({ t, children, dialogOpen, data, openButtonSX }) => {
  const [innerOpen, innerSetOpen] = useState(false);
  const open = dialogOpen ?? innerOpen;
  const setOpen = data.setDialogOpen ?? innerSetOpen;
  const {
    title,
    hideOpenButton,
    openButtonType,
    openButtonColor,
    openButtonText,
    openButtonFunc,
    openButtonDisabled,
    okButtonText,
    okButtonFunc,
    okButtonIsLoading,
    okButtonDisabled,
    cancelButtonText,
    cancelButtonFunc,
    cancelButtonIsLoading,
    extraButtonText,
    extraButtonFunc,
    extraButtonIsLoading,
    alertMessages,
  } = parseData(t, data, setOpen);

  return (
    <>
      { !hideOpenButton && 
          <OpenButton 
            buttonText={openButtonText} 
            openButtonType={openButtonType} 
            openFunc={openButtonFunc}
            disabled={openButtonDisabled}
            sx={openButtonSX}
          />
      }
      
      <Dialog
        open={open}
        onClose={ () => setOpen(false) }
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle 
          sx={{
            color:"secondary.main", 
            //height: "2.5em"
            }} 
            id="scroll-dialog-title"
            >
          {title}
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions sx={{height: "3.5em"}}>
          { !!cancelButtonText &&
             <ButtonSmall  
                width="26%" 
                text={t(cancelButtonText)}
                onClick={cancelButtonFunc}
                isLoading={cancelButtonIsLoading}
                sx={{ml:2}}
              />
          }
          { !!extraButtonText &&
             <ButtonSmall  
                width="26%" 
                text={t(extraButtonText)}
                onClick={extraButtonFunc}
                isLoading={extraButtonIsLoading}
                sx={{ml:2,mr:2}}
              />
          }
          <AlertMessages messages={alertMessages} />
          <ButtonSmall 
              width="70%" 
              text={t(okButtonText)}
              onClick={okButtonFunc}
              isLoading={okButtonIsLoading}
              disabled={okButtonDisabled}
              sx={{mr:2}}
              />
          </DialogActions>
      </Dialog>
    </>
  )
}
const RawDialog = withTranslation()(PureRawDialog);

const PureInfoDialog = ({t, data}) => {
  const [open, setOpen] = useState(false);
  // parse & translate & defaultvalues
  const { 
    title,
    openButtonType,
    openButtonText,
    okButtonText,
    cancelButtonText,
  } = parseData(t, data);
  
  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <OpenButton 
        buttonText={openButtonText} 
        openButtonType={openButtonType} 
        openFunc={() => setOpen(true)} 
        />
      <Dialog
        open={open}
        onClose={ () => setOpen(false) }
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        { title ?       
          <DialogTitle color="secondary.main"  id="scroll-dialog-title">
            {title}
          </DialogTitle>
          : false
        }
        <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          > 
            <div dangerouslySetInnerHTML={{__html: data.content}} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <ButtonSmall 
            margin={0}
            width="26%" 
            text="ok" 
            onClick={ () => setOpen(false) }
            />
        </DialogActions>
      </Dialog>
    </>
  );
}
const InfoDialog = withTranslation()(PureInfoDialog);

const RawPureConfirmDialog = ({ 
  t, 
  title,
  children, 
  dialogOpen,
  onCloseFunc,
  hideOpenButton,
  openButtonType,
  openButtonText,
  openButtonFunc,
  openButtonSX,
  okButtonText,
  okButtonFunc,
  okButtonIsLoading,
  okButtonDisabled,
  cancelButtonText,
  cancelButtonFunc,
  cancelButtonIsLoading,
  }) => {

  return (
    <>
      { !hideOpenButton && 
          <OpenButton 
            buttonText={openButtonText} 
            openButtonType={openButtonType} 
            openFunc={openButtonFunc}
            sx={openButtonSX}
          />
      }
      
      <Dialog
        open={dialogOpen}
        onClose={onCloseFunc}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent >
          {children}
          <Box style={{display: "flex", flexDirection: "row", justifyContent: "space-around"}} >
            <ButtonSmall
                width="26%" 
                text={t(cancelButtonText)}
                onClick={cancelButtonFunc}
                isLoading={cancelButtonIsLoading}
                sx={{ml:2}}
              />
            <ButtonSmall 
                width="26%" 
                text={t(okButtonText)}
                onClick={okButtonFunc}
                isLoading={okButtonIsLoading}
                disabled={okButtonDisabled}
                sx={{mr:2}}
                />
            </Box>
          </DialogContent>
      </Dialog>
    </>
  )
}
const RawConfirmDialog = withTranslation()(RawPureConfirmDialog);

export {
  OpenButton,
  InfoDialog, 
  RawDialog,
  RawConfirmDialog
};