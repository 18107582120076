import { withTranslation } from 'react-i18next';

import { 
  Tooltip 
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

const iconComponent = {
  ONLINE: CheckIcon,
  OFFLINE: WarningRoundedIcon,
  UNKNOWN: QuestionMarkIcon, //default"
}

const iconColor = {
  ONLINE: "success",
  OFFLINE: "error",
  UNKNOWN: "warning" //default"
}

const TooltipWrapper = withTranslation()(({t, children, tooltipTitle, disabled, status}) => {
  if(disabled){
    return <>{children}</>;
  }
  return <Tooltip title={tooltipTitle ?? t(status?.toLowerCase() ?? "unknown")}>{children}</Tooltip>;
})

export default ({t, status, tooltipTitle, tooltipDisabled}) => {
  const Icon = iconComponent?.[status] || iconComponent.UNKNOWN;
  const color = iconColor?.[status] || iconColor.UNKNOWN;
  
  return (
    <TooltipWrapper tooltipTitle={tooltipTitle} disabled={tooltipDisabled} status={status} >
      <Icon color={color} fontSize="small" />
    </TooltipWrapper>
  );
}