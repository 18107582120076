import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { 
  selectBrand, 
  selectCurrentPage, 
  selectLoginType, 
  selectThemeMode, 
  themeModeSet,
} from '../data/uiSlice.js';
import { selectUserPhonenumber } from '../data/devicesSlice.js';

import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material/';
import LogoutIcon from '@mui/icons-material/Logout';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import { RadioButtonsForMenu } from './RadioButtons.js';
import logout from '../utility/logout.js';

const PureUserMenu = ({t}) => {
  const dispatch = useDispatch();
  const loginType = useSelector(selectLoginType);
  const page = useSelector(selectCurrentPage);
  const mode = useSelector(selectThemeMode);
  const userPhotoUrl = useSelector(state => state.ui.userPhotoUrl);
  const userName = useSelector(state => state.ui.userName);
  const userPhonenumber = useSelector(selectUserPhonenumber);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleThemeModeChange = () => {
    dispatch(themeModeSet(mode === "light" ? "dark" : "light"));
  };

  if(["landingPage","urlLogin","none"].includes(page)){
    return false
  }

  const userInfo = () => {
    const margin = "0.5em 2em";
    if(loginType === "pin"){
      return (
        <Box style={{margin}}>
          {t("logged-with-pin-code")}
        </Box>
      )
    }
    return (
      <Box style={{margin, display: "flex", justifyContent: "space-between" }}>
        { !!userName && <Box>{userName}</Box>  }
        <Box>{userPhonenumber}</Box>
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: "row" }}>
      <Tooltip title={t("account-settings")}>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ p: 0 }}>
          <Avatar 
            sx={{ width: 32, height: 32, border: `1px solid white`, }} 
            alt={userName} 
            src={userPhotoUrl} 
            />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '5px' }}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {userInfo()}
        <Divider sx={{ my: 0.5 }}/>
        <MenuItem>
          <RadioButtonsForMenu type="hourCycle"  />
        </MenuItem>
        <MenuItem>
          <RadioButtonsForMenu type="unitSystem"  />
        </MenuItem>
        <Divider sx={{ my: 0.5 }}/>
        <MenuItem >
          <ListItemIcon sx={{ml:2}}>
            <LogoutIcon onClick={logout} />
          </ListItemIcon>
          <ListItemText>
            {t('log-out')}
          </ListItemText>
        </MenuItem>
      </Menu>
      <Box sx={{ml: 1, mt: 0.5}}>{userName}</Box>
    </Box>  
  );
}
const UserMenu = withTranslation()(PureUserMenu);
export {
  UserMenu,
};
