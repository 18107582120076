import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import { useQuery  } from "react-query";
import { useDispatch } from "react-redux";
import i18next from 'i18next';
import TelegramLoginButton from 'react-telegram-login';
import {SvgIcon} from '@mui/material';
import LeakguardIcon from './Icons/HumidityIcon.js';

import logger from '../utility/logger.mjs';
import { 
  countryToLanguage, 
} from '../utility/userConfig.mjs';
import { 
  devicesAdded, 
  deviceAdded, 
  privilegesSet,
} from '../data/devicesSlice.js';
import { 
  uiInit,
  brandSet,
  loginTypeSet,
  currentProjectIdSet,
} from '../data/uiSlice.js';
import {
  locationsInit
} from '../data/locationsSlice.js';


import { AlertError } from './Alert.js';
import MainPage from "../pages/MainPage.js";
import DeviceInfo from "../pages/DeviceInfo.js";
import { 
  checkToken, 
  getDevicesByPhonenumber, 
  checkTelegramHash,
  checkURLcode,
  getDeviceByUUID,
  getProjectsAndLocations,
} from "../server/serverOperation.js";

import { 
  CircularProgress, 
  Box 
} from '@mui/material';
import CollapseButton from './CollapseButton.js';

const IS_PUBLIC_VERSION = Number(process.env.REACT_APP_IS_PUBLIC_VERSION);
const TELEGRAM_BOT_NAME = process.env.REACT_APP_TELEGRAM_BOT_NAME;
// A Telegram mock response for testing
var mockResponse = false;
if(!IS_PUBLIC_VERSION){
  mockResponse = JSON.parse(process.env.REACT_APP_TELEGRMA_MOCK_RESPONSE)
}

const TelegramLogger = ({t, urlCodeLogin=false, telegramLinkLogin=false}) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(false);
  const [telegramResponse, setTelegramResponse] = useState(false);
  const [isPinOK, setIsPinOK] = useState(false);
  const { urlParm } = useParams();
  if(!urlCodeLogin && !telegramLinkLogin){
    dispatch(loginTypeSet("landingPage"));
  }
  if(errorMessage){
    dispatch(loginTypeSet("landingPage"));
  }

  const { data:tgButtonCheckData, refetch:executeTelegramCheck, isLoading:tgButtonIsLoading, isSuccess:telegramLinkLoginSuccess } = useQuery(
    ["checkTelegramHash", IS_PUBLIC_VERSION ? telegramResponse : mockResponse],
    async ({ queryKey }) => {
      const [_, telegramResponse] = queryKey;
      const res = await checkTelegramHash(IS_PUBLIC_VERSION ? telegramResponse : mockResponse);
      if (!res.ok) {
        setErrorMessage(res?.error);
        throw new Error('checkTelegramHash: ' + res?.error);
      }
      return res.data;
    },
    { enabled: !!telegramResponse }
  );

  // const { data: tgButtonCheckData, isLoading: tgButtonIsLoading, isSuccess: telegramLinkLoginSuccess } = useQuery(
  //   ["checkTelegramHash", telegramResponse],
  //   async ({ queryKey }) => {
  //     const [_, telegramResponse] = queryKey;
  //     const res = await checkTelegramHash(telegramResponse);
  //       if (!res.ok) {
  //         setErrorMessage(res?.error);
  //         throw new Error('checkTelegramHash: ' + res?.error);
  //       }
  //       return res.data;
  //   },
  //   { enabled: !!telegramResponse }
  // );

  const { data:urlCodeCheckData, isLoading:urlCodeCheckDataIsLoading, isSuccess:urlCodeCheckIsSuccess, isError:urlCodeCheckDataIsError } = useQuery(
    ["checkToken", urlParm],
    async () => {
      const res = await checkURLcode(urlParm);
      if (!res.ok) {
        setErrorMessage(res?.error);
        throw new Error('urlCodeCheckData' + res?.error);
      }
      return res.data
    },
    { enabled: urlCodeLogin }
  );

  const { data:tgLinkCheckData, isLoading:tgLinkCheckIsLoading, isSuccess:telegramLinkSuccess } = useQuery(
    ["checkToken", urlParm],
    async () => {
      const res = await checkToken(urlParm);
      if (!res.ok) {
        setErrorMessage(res?.error);
        throw new Error('tgLinkCheck ' + res?.error);
      }
      return res
    },
    { enabled: telegramLinkLogin }
  );

  const { data:devices, isLoading: devicesIsLoading, isSuccess:fetchDevicesSuccess} = useQuery(
    ["getDevices"],
    async () => {
      let res = {};
      if(urlCodeLogin){
        res = await getDeviceByUUID(urlCodeCheckData.uuid);
      } 
      else {
        const phoneId = tgLinkCheckData?.appUserPhoneId ?? tgButtonCheckData.appUserPhoneId;
        res = await getDevicesByPhonenumber(phoneId);
      }
      if (!res?.ok) {
        setErrorMessage(res?.error);
        throw new Error('getDevices: ' + res?.error);
      }
      return res.data
    },
    { enabled: !!tgButtonCheckData || !!tgLinkCheckData || isPinOK }
  );

  // const { data:deviceTypeData, isLoading:devicesTypesIsLoading, isSuccess:fetchDevicesTypesSuccess} = useQuery(
  //   ["getDeviceTypes"],
  //   async () => {
  //     let res = {};
  //     if(devices.lenght > 0){
  //       const deviceIds = devices.map( d => d.id );
  //       res = await getDeviceTypes(deviceIds);
  //     } 
  //     else {
  //       setErrorMessage("getDeviceTypesQuery: no devies");
  //       throw new Error("getDeviceTypesQuery: no devies");
  //     }
      
  //     if (!res?.ok) {
  //       setErrorMessage(res?.error);
  //       throw new Error('getDeviceTypes: ' + res?.error);
  //     }
  //     return res.data
  //   },
  //   { enabled: !!devices }
  // );

  const { data:locationData, isLoading:locationsIsLoading, isSuccess:fetchlocationsSuccess} = useQuery(
    ["getLocations"],
    async () => {
      if(urlCodeLogin){
        return [];
      } 
      const phoneId = tgLinkCheckData?.appUserPhoneId ?? tgButtonCheckData.appUserPhoneId;
      const res = await getProjectsAndLocations(phoneId);
      if (!res?.ok) {
        setErrorMessage(res?.error);
        throw new Error('getLocations: ' + res?.error);
      }
      return res.data
    },
    { enabled: !!devices }
  );

  
  if(!!locationData){
    if(locationData?.projects != null && locationData?.locations != null && locationData?.locationTypes != null){
      dispatch(locationsInit(locationData));
      console.log("locationData",locationData)
      dispatch(currentProjectIdSet(locationData?.projects?.[0]?.id));
    }
  }
  
  if(!!devices){
    if(urlCodeLogin){
      dispatch(devicesAdded([ {...devices, dataIsLoaded: false} ]));
      dispatch(uiInit({ 
        id: devices.id, 
        loginType: "pin",
      }));
      i18next.changeLanguage(countryToLanguage(devices?.country));
      dispatch(privilegesSet({ deviceId: devices.id, privileges:"superadmin" }));
      dispatch(brandSet(urlCodeCheckData.brand))
    }
    else {
      const { 
        deviceId:id,
        appUserPhoneId:userPhoneId, 
        language,
        unitSystem,
        hourCycle, 
        termsAccepted, 
        privacyPolicyAccepted,
        brand
      } = tgLinkCheckData ?? tgButtonCheckData;
      const augmentedDeviceList = devices.map(dev => ({ ...dev, dataIsLoaded: false}));
      dispatch(devicesAdded(augmentedDeviceList));
      dispatch(uiInit({ 
        id, 
        userPhoneId, 
        language, 
        unitSystem,
        hourCycle,
        termsAccepted, 
        privacyPolicyAccepted,
        loginType: "telegram",
        brand,
        userPhotoUrl: IS_PUBLIC_VERSION ? telegramResponse.photo_url : mockResponse.photo_url,
        userName: IS_PUBLIC_VERSION ? telegramResponse.first_name : mockResponse.first_name,
      }));
      i18next.changeLanguage(language);
    }
  }

  useEffect(() => {
    setErrorMessage(tgLinkCheckData?.error ?? false);
  }, [tgLinkCheckData]);

  if( tgButtonIsLoading         || 
      urlCodeCheckDataIsLoading || 
      tgLinkCheckIsLoading      || 
      devicesIsLoading          ||
      locationsIsLoading           ){
    return <Box sx={{mt:10}}><CircularProgress /></Box>
  }

  if(urlCodeLogin && !isPinOK && !!urlCodeCheckData?.isActive ){
    dispatch(deviceAdded(urlCodeCheckData));
    return (
      <DeviceInfo 
        setIsPinOK={setIsPinOK}
        errorMessage={errorMessage}
      />
    )
  }

  if(!IS_PUBLIC_VERSION){
    if(urlCodeCheckDataIsError || (!fetchDevicesSuccess && !devices) ){
      if(errorMessage && ( urlCodeLogin || telegramLinkLogin )){
        return (
          <Box sx={{m:10}}>
            <AlertError text={errorMessage} title={false} />
         </Box>
        )
      }
      else{
        return (
          <Box sx={{m:10}}>
            <CollapseButton open={true} type="tree" onClick={() => {}} hide />
            <button onClick={executeTelegramCheck}>Telegram login</button> 
            <AlertError text={errorMessage} title={false} />
         </Box>
        )
      }
    }
  }
  
  if(IS_PUBLIC_VERSION){
    if (!fetchDevicesSuccess && !devices) {
      if (errorMessage && (urlCodeLogin || telegramLinkLogin)) {
        return (
          <Box sx={{ m: 10 }}>
            <AlertError text={errorMessage} title={false} />
          </Box>
        )
      }
      return (
        <Box sx={{ m: 7 }}>
          <TelegramLoginButton
            botName={TELEGRAM_BOT_NAME}
            dataOnauth={(response) => setTelegramResponse(response)}
          //language={language}
          />
          <br />
          {!!errorMessage && <AlertError text={errorMessage} title={false} />}
        </Box>
      )
    }
  }

  let pagenNbr = telegramLinkLoginSuccess ? 2 : 0;
  return (
    <>{ fetchDevicesSuccess && devices && <MainPage openPage={pagenNbr}/>}</>
  )

  
}

export default TelegramLogger;
