import LocationAddDialog from "./LocationAddDialog";
import AlarmListDialog from "../Alarms/AlarmListDialog";

import { 
  Portal,
  Stack,
  styled,
} from "@mui/material";
import { 
  GridToolbarQuickFilter as MuiGridToolbarQuickFilter, 
} from "@mui/x-data-grid-pro";

const GridToolbarQuickFilter = styled(MuiGridToolbarQuickFilter)(({ theme }) => ({
  '& .MuiInputBase-root': {
    width: '100%',
  },
  '& .MuiInputBase-input': {
    width: '100%',
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '8ch',
      marginRight: -20,
      '&:focus': {
        width: '25ch',
      },
    },
  },
}));

export default function LocationTableToolbar(){
  return (
    <>
      <Portal container={() => document.getElementById('location-add-dialog')}>
        <LocationAddDialog />
      </Portal>
      <Portal container={() => document.getElementById('alarm-list-dialog')}>
        <AlarmListDialog />
      </Portal>
      <Stack 
        direction="row" 
        spacing={1} 
        sx={{
          mx:1,
          justifyContent: "right",
          alignItems: "center",
          }} 
        >
        <GridToolbarQuickFilter />
      </Stack>
    </>
  );
}
