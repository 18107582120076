import { Badge } from "@mui/material";

const statusColor = {
  ONLINE: "success",
  OFFLINE: "error",
  //default: "default",
}

export default ({children, status}) => {
  return(
    <Badge 
      color={statusColor[status] || "warning"} 
      variant={"dot"} 
      >
      {children}
    </Badge>
  );
}
