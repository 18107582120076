import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from 'react-i18next';

import { selectCurrentProjectId, currentProjectIdSet } from "../../data/uiSlice";

import { 
  FormControl,
  TextField,
  InputLabel,
  MenuItem,
  Select 
} from "@mui/material";

const PureProjectSelect = ({t}) => {
  const dispatch = useDispatch();
  const projects = useSelector(state => state.locations.project);
  const currenProjectId = useSelector(selectCurrentProjectId);

  const onSelectChange = (event) => {
    dispatch(currentProjectIdSet(event.target.value));
  }

  const fieldSize = "small";
  const sx = { width: 155, textAlign: "left" };

  console.log("projects",projects)

  if(projects == null || projects.length === 0){
    return false;
  }

  if(projects.length === 1){
    return (
      <TextField  
        sx={sx}
        size={fieldSize}
        label={t("project")}
        value={projects[0].name}
        color="primary"
        InputProps={{
            readOnly: true,
          }}
        />
    )
  }

  return (
    <FormControl size={fieldSize} sx={sx}>
      <InputLabel  id="project-select-input-label">{t("project")}</InputLabel>
      <Select
        label={t("project")}
        //variant={variant}
        id="projectSelect"
        value={currenProjectId}
        onChange={onSelectChange}
        //renderValue={renderValue}
      >
        { projects.map( p => 
          <MenuItem key={p.id} value={p.id}>
            {p.name}
          </MenuItem>) 
        } 
      </Select>
    </FormControl>
  )
}
const ProjectSelect = withTranslation()(PureProjectSelect);
export default ProjectSelect;