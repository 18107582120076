import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 6.35 6.35"
    stroke="currentColor"
    overflow="hidden"
    fill="none"
  >
    <defs>
      <clipPath id="a">
        <path fillRule="evenodd" d="M642 317h57v55h-57z" clipRule="evenodd" />
      </clipPath>
      <clipPath id="b">
        <path fillRule="evenodd" d="M646 233h42v46h-42z" clipRule="evenodd" />
      </clipPath>
    </defs>
    <g
      style={{
        display: "inline",
      }}
    >
      <g
        fill="currentColor"
        strokeWidth={2.335}
        style={{
          overflow: "hidden",
        }}
      >
        <path
          d="M49.72 11.54a8.006 8.006 0 0 1-8.006 8.006 8.006 8.006 0 1 1 0-16.01 8.006 8.006 0 0 1 8.006 8.005zM62.029 42.764 51.722 39.36s-5.704-13.209-5.905-13.61c-1.401-2.501-4.003-4.203-7.005-4.203-1.201 0-2.402.3-3.402.8L21.4 27.853c-1.002.4-1.802 1.2-2.203 2.201l-5.003 12.009c-.8 2.002.1 4.403 2.2 5.205.501.2 1.002.3 1.502.3 1.602 0 3.103-.901 3.703-2.503l4.103-10.407 4.203-1.601-6.904 33.725L9.59 83.094c-1.401 1.7-1.2 4.203.5 5.604.701.6 1.602.9 2.502.9 1.202 0 2.302-.5 3.103-1.5l14.01-17.013c.4-.5.7-1.101.8-1.702l2.403-11.608 10.807 7.805v20.016a4.015 4.015 0 0 0 4.003 4.002 4.014 4.014 0 0 0 4.004-4.002V63.58c0-1.302-.601-2.502-1.602-3.203l-9.707-7.105 2.702-13.51 1.902 4.403c.5 1 1.3 1.801 2.401 2.201l12.01 4.003c.4.1.8.201 1.3.201 1.7 0 3.202-1.101 3.803-2.703.7-2.1-.4-4.403-2.502-5.103z"
          style={{
            display: "inline",
            overflow: "hidden",
          }}
          transform="matrix(.07117 0 0 .07117 .584 -.126)"
        />
      </g>
    </g>
  </svg>
);




