import { useState, useEffect  } from "react";
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from "react-query";
import { toast } from 'react-toastify';

import logger from '../utility/logger.mjs';
import { toLowerCaseFirst } from "../utility/function.mjs";
import { sendNewDeviceName } from "../server/serverOperation.js";
import { currentPageSet } from "../data/uiSlice.js";
import { 
  selectCurrentDevice,
  deviceNameSet,
  selectCurrentActivationTime,
  selectPrivileges,
} from '../data/devicesSlice.js';
import SettingsList from "../components/SettingsList.js";
// import PhonenumberEditor from "../components/Phonenumbers/PhonenumberEditor2.js";
// import PhonenumberEditDialog from "../components/Phonenumbers/PhonenumberEditDialog.js";
import Phonenumbers from '../components/Phonenumbers/Phonenumbers.js'
import { Timezone } from "../components/PrettyTime.js";
import PinEditor from "../components/PinEditor.js";
import { DataList } from "../components/DataList/DataList.js";
import { AlertWarning } from "../components/Alert.js";

import { Box, Typography } from "@mui/material";
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import EditButton from '@mui/icons-material/Edit';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { DEVICE_NAME_LENGTH_MAX } from "../utility/deviceConfig.js";
import DeviceSelect from "../components/Devices/DeviceSelect.js";
import DeviceHeader from "../components/Devices/DeviceHeader.js";

const PureDeviceDetails = ( {t, editDisabled} ) => {
  const dispatch = useDispatch();
  const privileges = useSelector(selectPrivileges);
  const disabled = editDisabled || (privileges === "guest");
  const {id:deviceId, name, type, uuid} = useSelector(selectCurrentDevice);
  const [showNameEdit, setShowNameEdit] = useState(false);
  const [newName, setNewName] = useState("");
    
  const handleSaveClick = () => {
    if(newName.length > 0){
      mutate({deviceId, newName})
    }
  }

  const { mutate, isLoading } = useMutation(
    sendNewDeviceName, // parameter {deviceId, newName}
    {
      onSuccess: async (data) => {
        if(data.ok){
          dispatch(deviceNameSet({deviceId, newName}));
          setNewName("");
          setShowNameEdit(false);
          toast.success(t("device-name-updated-info"));
        }
        else{
          toast.error(t("device-name-not-updated-error"));
        }
      },
      onError: (error) => {
        toast.error(t("device-name-not-updated-error"));
        logger.error(error);
      }
    }
  );

  const deviceNameEditButton = () => {
    if(disabled){
      return false;
    }
    return (
      <IconButton onClick={ () => setShowNameEdit(true) }>
        <EditButton fontSize={"small"}/>
      </IconButton>
    )
  }

  const handleCancelClick = () => {
    setShowNameEdit(false);
    setNewName("");
  }

  const data = [
    [t("type"), t(type)],
    [t("id"), t(uuid)],
    [toLowerCaseFirst(t("timezone")), <Timezone />],
    [t("user-type"), t(privileges)],
  ]

  const devInfo = () => {
    return (
      <Box sx={{width: 260, margin: 'auto'}}>
        <DataList data={data} />
      </Box>
    )
  }

  if(!showNameEdit){
    return (
      <>
        <Typography variant="h2">
          { name } 
          { deviceNameEditButton() }
        </Typography> 
        { devInfo() }
      </>
    )
  }

  const saveButtonDisabled = isLoading || newName.length === 0 || newName === name;

  return (
    <>
      <Input 
          id="deviceId" 
          type="text" 
          name="devicename"
          placeholder={name}
          onChange={ e => setNewName(e.target.value.trim()) }
          inputProps={{ maxLength: DEVICE_NAME_LENGTH_MAX }}
          sx={{ mb:1.8 }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton 
                onClick={handleSaveClick} 
                disabled={saveButtonDisabled}
                >
                <CheckCircleOutlineOutlinedIcon color={saveButtonDisabled ? "disbaled" : "success"} fontSize={"small"}/>
              </IconButton>
              <IconButton 
                onClick={handleCancelClick}
                disabled={isLoading}
                >
                <CancelOutlinedIcon color="warning" fontSize={"small"}/>
              </IconButton>
            </InputAdornment>
          }
        />
      { devInfo() }
    </>
  )
}
const DeviceDetails = withTranslation()(PureDeviceDetails);

function SubPageSettings({t}) {
  useDispatch()(currentPageSet("MAIN"));
  const disabled = useSelector(selectPrivileges) === "guest";
  const activationTime = useSelector(selectCurrentActivationTime);

  if(activationTime == null){
    return (
      <>
        <Box sx={{mb: 2}}>
          <DeviceDetails editDisabled />
        </Box>
        <AlertWarning text={t("device-not-activated-warning")} title={false} />
      </>
    );
  }

  return (
    <>
      <DeviceHeader />
      {/* <Box sx={{mb: 2}}>
        <DeviceDetails />
      </Box> */}
      <SettingsList />
      <Phonenumbers />
      { !disabled && <PinEditor /> }
    </>
  );
}

 export default withTranslation()(SubPageSettings);
