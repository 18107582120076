import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 6.35 6.35"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <g
      style={{
        fill: "#fff",
        fillOpacity: 1,
        strokeWidth: 0.310913,
        strokeDasharray: "none",
      }}
    >
      <g
        style={{
          fill: "#fff",
          fillOpacity: 1,
          strokeWidth: 0.437397,
          strokeDasharray: "none",
        }}
      >
        <path
          d="m19.364-7.458-.525 1.96v6.854h-.781v.612h1.091v2h.364"
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "#000",
            strokeWidth: 0.437397,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "none",
            paintOrder: "stroke fill markers",
          }}
          transform="rotate(135 9.897 -2.121) scale(.94651 .67649)"
        />
        <path
          d="m19.37-7.451.526 1.96v6.854h.781v.611h-1.091v2.001h-.364"
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "#000",
            strokeWidth: 0.437397,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "none",
            paintOrder: "stroke fill markers",
          }}
          transform="rotate(135 9.897 -2.121) scale(.94651 .67649)"
        />
      </g>
    </g>
    <path
      d="m5.985 5.885-4.18-4.179"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        stroke: "#000",
        strokeWidth: 0.25,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeDasharray: "none",
        paintOrder: "stroke fill markers",
      }}
    />
    <g
      style={{
        fill: "#fff",
        fillOpacity: 1,
        strokeWidth: 0.310913,
        strokeDasharray: "none",
      }}
    >
      <g
        style={{
          fill: "#fff",
          fillOpacity: 1,
          strokeWidth: 0.437397,
          strokeDasharray: "none",
        }}
      >
        <path
          d="m19.364-7.458-.525 1.96v6.854h-.781v.612h1.091v2h.364"
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "#000",
            strokeWidth: 0.437397,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "none",
            paintOrder: "stroke fill markers",
          }}
          transform="rotate(45 8.283 -16.21) scale(.94651 -.67649)"
        />
        <path
          d="m19.37-7.451.526 1.96v6.854h.781v.611h-1.091v2.001h-.364"
          style={{
            fill: "#fff",
            fillOpacity: 1,
            stroke: "#000",
            strokeWidth: 0.437397,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeDasharray: "none",
            paintOrder: "stroke fill markers",
          }}
          transform="rotate(45 8.283 -16.21) scale(.94651 -.67649)"
        />
      </g>
    </g>
    <path
      d="m.374 5.9 4.18-4.18"
      style={{
        fill: "#fff",
        fillOpacity: 1,
        stroke: "#000",
        strokeWidth: 0.25,
        strokeLinecap: "round",
        strokeLinejoin: "miter",
        strokeDasharray: "none",
        paintOrder: "stroke fill markers",
      }}
    />
    <path
      d="M3.135 6.14S.62 5.922.62 1.783c1.932.35 2.536-1.43 2.536-1.43S3.76 2.13 5.692 1.782c0 4.138-2.515 4.356-2.515 4.356"
      style={{
        display: "inline",
        fill: "#fff",
        fillOpacity: 1,
        stroke: "#000",
        strokeWidth: 0.35,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeDasharray: "none",
        paintOrder: "stroke fill markers",
      }}
    />
    <g
      style={{
        strokeWidth: 0.186851,
        strokeDasharray: "none",
      }}
    >
      <path
        d="M2.987.984v4.782S.976 5.58.824 2.165c0 0 1.25 1.257 2.163-1.181zM3.295.986v4.782s2.011-.187 2.164-3.601c0 0-1.252 1.257-2.164-1.181z"
        style={{
          fill: "none",
          fillOpacity: 1,
          stroke: "#000",
          strokeWidth: 0.186851,
          strokeLinecap: "round",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          paintOrder: "stroke fill markers",
        }}
        transform="matrix(.80278 0 0 .80278 .633 .811)"
      />
    </g>
  </svg>
);