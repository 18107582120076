import { withTranslation } from 'react-i18next';
import PhoneInputForm from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useSelector } from 'react-redux';

import { twilioCountries } from '../../utility/userConfig.mjs';
import { selectCurrentCountry } from '../../data/devicesSlice';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select } from '@mui/material';

const PurePhonenumberInput = ({t, number, setNewNumber, userType, setUserType, userTypes, inDialog=false}) => {
  const country = useSelector(selectCurrentCountry);

  const handleSelect = (event) => {
    setUserType(event.target.value);
  };
  
  return (
    <>
      <PhoneInputForm
        inputStyle={{width:'240px'}}
        country={twilioCountries.includes(country) ? country : 'us'}
        preferredCountries={['us','fi','se','au']}
        onlyCountries={twilioCountries}
        value={number}
        placeholder={t('phonenumber')}
        onChange={(value, event) => setNewNumber({"number": `+${value}`, "country": event.countryCode})}
      />
      { !inDialog && 
          <FormControl  sx={{ m: 1, minWidth: 150 }} size="small">
          <InputLabel id="select-label">User type</InputLabel>
          <Select
            labelId="select-standard-label"
            id="select-user-type"
            value={userType}
            defaultValue={userType}
            disabled={ inDialog }
            onChange={handleSelect}
            label={t("user-type")}
          >
            <MenuItem key={userTypes[0]} value={userTypes[0]}>{t(userTypes[0])}</MenuItem>
            <MenuItem key={userTypes[3]} value={userTypes[3]}>{t(userTypes[3])}</MenuItem>
            <MenuItem key={userTypes[1]} value={userTypes[1]}>{t(userTypes[1])}</MenuItem>
          </Select>
        </FormControl>
      }
    </>
  )
}

const PhonenumberInput = withTranslation()(PurePhonenumberInput);
export default PhonenumberInput;