import { 
  createSlice, 
  createAsyncThunk, 
  createSelector, 
} from "@reduxjs/toolkit";

import { selectCurrentProjectId } from "./uiSlice";
import { findById } from "../utility/function.mjs";

const initialState = { 
  alarm: [],
  dataIsLoaded: false,
  error: null
};

export const alarmSlice = createSlice({
  name: 'alarm',
  initialState,
  reducers: {
    alarmInit: (state, action) => {
      state.alarm = action.payload.map( (a,i) => ({...a, id: i, dbId: a.id}));
      //state.dataIsLoaded = true;
    },
  }
});


export const selectAllAlarms = state => state.alarms.alarm;

export const selectCurrentProjectAlarms = createSelector(
  [selectAllAlarms, selectCurrentProjectId],
  (alarms, currentProjectId) => alarms.filter( alarm => (alarm.projectId === currentProjectId))
);

export const selectLocationSubAlarms = locationId => state => {
  // Find all devices of the sublocations of the given location.
  const subLocationDeviceIds = (function findDevices(locationId){
    const currentLocation = findById(state.locations.location, locationId);
    if(currentLocation == null){
      return []
    }
    return currentLocation.devices.concat(currentLocation.children.map( id => findDevices(id)).flat())
  })(locationId) 
  // Return unacknowledged alarms of these devices.
  return state.alarms.alarm.filter( a => subLocationDeviceIds.includes(a.deviceId) && !a.alarmAckedByUserPhone && !a.alarmAckedByUserTG)
}

export const findLocationSubAlarmInfo = (locationId) => state => {
  const locationsInPathsToAlarms = new Set();
  let isTopNode = false;
  let locationHasAlarms = false;
  let alarmCount = 0;
  const alarmLocationIds = new Set();
  if(locationId === -1){
    return {locationsInPathsToAlarms, isTopNode, locationHasAlarms, alarmCount, alarmLocationIds};
  }
  // Consider only locations that belong to the same project as the given location
  // and return if project or location is not found.
  const projectId = state.locations.location.find( l => l.id === locationId)?.projectId;
  const projectLocations = state.locations.location.filter( l => l.projectId === projectId);
  if(projectId == null || projectLocations.length === 0){
    return {locationsInPathsToAlarms, isTopNode, locationHasAlarms, alarmCount, alarmLocationIds};
  }
  // Filter active alarms.
  const alarms = new Set(state.alarms.alarm.filter( a => !a.alarmAckedByUserPhone && !a.alarmAckedByUserTG ));
  // Check if given location has alarms.
  const currentLocation = projectLocations.find( l => l.id === locationId);
  if(currentLocation.parentId == null){
    isTopNode = true;
  }
  if(currentLocation != null){
    alarms.forEach( alarm => {
      if(currentLocation.devices.includes(alarm.deviceId)){
        locationHasAlarms = true;
      }
    })
  }
   // Check recursively all the sublocations for active alarms, count alarms and get ids of the locations with alarms
  (function findPaths(locationId) {
    const location = projectLocations.find( l => l.id === locationId);
    let alarmIsFound = false;
    if(location != null){
      alarms.forEach( alarm => {
        if(location.devices.includes(alarm.deviceId)){
          alarms.delete(alarm);
          alarmCount++;
          alarmIsFound = true;
          alarmLocationIds.add(locationId);
        }
      })
      location.children.forEach( childId => {
        if(findPaths(childId)){
          locationsInPathsToAlarms.add(locationId);
          alarmIsFound = true;
        }
      })
    }
    return alarmIsFound;
  })(locationId)
  return {locationsInPathsToAlarms, isTopNode, locationHasAlarms, alarmCount, alarmLocationIds};
};


//   const alarm = state.alarms.alarm.filter( a => alarmIds.includes(a.id));
//   if(alarm == null) return [];
//   const device = state.device.devices.find( d => d.id === alarm.deviceId);
//   if(device == null) return [];
//   const locations 
//   const subLocationDeviceIds = (function findDevices(locationId){
//     const currentLocation = findById(state.locations.location, locationId);
//     if(currentLocation == null){
//       return []
//     }
//     return currentLocation.devices.concat(currentLocation.children.map( id => findDevices(id)).flat())
//   })(locationId) 
//   // Return unacknowledged alarms of these devices.
//   return state.alarms.alarm.filter( a => subLocationDeviceIds.includes(a.deviceId) && !a.alarmAckedByUserPhone && !a.alarmAckedByUserTG)
// }

export const selectAlarmsByDeviceId = id => state => state.alarms.alarm.filter( a => a.deviceId === id);

// alarms are not directly linked to projects ==> would need to filter by devices etc...
// export const selectCurrentProjectAlarms = createSelector(
//   [selectAllAlarms, selectCurrentProjectId],
//   (alarms, currentProjectId) => alarms.filter( a => (a.projectId === currentProjectId))
// );

export const { 
  alarmInit,
} = alarmSlice.actions;

export default alarmSlice.reducer;