import { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from "react-query";


import { checkMode } from '../theme.js';
import logger from '../utility/logger.mjs';
import { 
  selectDeviceById,
} from '../data/devicesSlice.js';
import { 
  selectCurrentDeviceId, 
  currentDeviceSet, 
  selectLoginType,
  timeRangeSet,
  selectLanguage,
  currentPageSet,
  brandSet,
  themeModeSet,
  selectThemeMode,
} from '../data/uiSlice.js';
import MapDialog from '../components/MapDialog.js';
import DeviceDetails from '../components/Devices/DeviceDetails.js';
import { 
  fetchOnomondoData, 
//  fetchActivationDates 
} from "../server/serverOperation.js";
import { ActivateDevicesDialog } from '../components/Devices/ActivateDevicesDialog.js';
import DeviceTable from '../components/Devices/DeviceTable.js'

import {
  Box,
  Collapse,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  IconButton,
  ListItemButton, 
} from '@mui/material/';
import { 
  LocationOff,
  LocationOn,
} from '@mui/icons-material/';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

const PureDeviceTableRow = ({t, dev:devTest, deactivateNameLink, selectSubPage, onomondoKnown, activationKnown}) => {
  const id = devTest.id;
  const dispatch = useDispatch();
  const dev = useSelector(selectDeviceById(id));
  const currentId = useSelector(selectCurrentDeviceId);
  const themeMode = useSelector(selectThemeMode);
  const ICON_SIZE = "small";
  const PROGRESS_ICON_SIZE = 15;
  //const devicesIdsAndIndexes = useSelector(state => state.devices.device).reduce( (acc,cur,i) => {return {...acc, [cur.id]: i}}, {});
  const privileges = useSelector(state => state.devices.device).find( o => o.id === dev.id).privileges;
  const [openMap, setOpenMap] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const position = [dev?.location?.lat, dev?.location?.lng];
  const positionOK = position[0] !== undefined && position[1] !== undefined;
  const languege = useSelector(selectLanguage);
  
  const mapButton = () => {
    let button = positionOK 
      ? <ListItemButton onClick={() => setOpenMap(true)}>
          <LocationOn color="success"/>
        </ListItemButton>
      : <ListItemButton >
          <LocationOff color="error"/>
        </ListItemButton>
    
    return (
        <Box sx={{display: "flex", justifyContent: "center", paddingRight: "3em"}} >
          <Box sx={{ width: "0em", margin: 0, padding: 0 }}>
            { button }
          </Box>
        </Box>
    )
  }
    
  const status = () => {
    if(dev?.deviceStatus === "ONLINE"){
      return <CheckCircleTwoToneIcon fontSize={ICON_SIZE} color="success" />
    }
    if(dev?.deviceStatus === "OFFLINE"){
      return <ErrorTwoToneIcon fontSize={ICON_SIZE} color="error" />
    }
    return <HelpOutlineIcon fontSize={ICON_SIZE} />
  }

  const handleDeviceClick = () => {
    if(!deactivateNameLink){
      dispatch(currentDeviceSet(dev.id));
      dispatch(brandSet(dev.brand));
      dispatch(themeModeSet(checkMode(dev.brand, themeMode)))
      dispatch(timeRangeSet(false));
      selectSubPage(0);
      }
  };

  const highlightCurrentDevice = currentId == dev.id 
    ? {border: "2px solid PaleGreen"}
    : {};


  return (
    <>
      <TableRow 
          key={dev.uuid}
          sx={{ '&:last-child td, &:last-child th': { border: 0 }, ...highlightCurrentDevice }}
      >
        <TableCell style={{ paddingLeft: "2%" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenDetails(!openDetails)}
          >
            {openDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell    >
          <ListItemButton onClick={handleDeviceClick}>
            {dev.name}
          </ListItemButton>
        </TableCell>
        {/* <TableCell align="center">
          { status() }
        </TableCell>
        <TableCell align="center" >
          { mapButton() }
          <MapDialog open={openMap} setOpen={setOpenMap} position={position} />
        </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={openDetails} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <DeviceDetails deviceId={id}  />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
const DeviceTableRow = withTranslation()(PureDeviceTableRow);

const PureSubPageDevices = ({t, selectSubPage}) => {
  useDispatch()(currentPageSet("DEVICES"));
  const loginType = useSelector(selectLoginType);
  // const deviceListData = useSelector(selectDeviceListData);
  // const onmondoUUIDs = deviceListData.filter( dev => dev.onomondoOK ).map( dev => dev.uuid );
  // const currentId = useSelector(selectCurrentDeviceId);
  // //const UUIDsWithoutActivationDate = deviceListData.filter( dev => dev.activationDate === undefined ).map( dev => dev.uuid ); 

  // const { data:onomondoData, refetch:refetchOnomondoData, status:onomondoDataStatus, isSuccess:onomondoDataIsSuccess } = useQuery(
  //   ["onomondoData"],
  //   () => {
  //     return fetchOnomondoData(onmondoUUIDs);
  //   },
  //   {
  //     refetchOnMount: true,
  //   }
  // );

  // const deviceTableRows = deviceListData.map( dev => {
  //   const uuid = dev.uuid;
  //   // const i = activationDates?.findIndex( d => d.uuid === uuid)
  //   // const activationDate = activationDates?.[i]?.activationDate.slice(0,10);
  //   // const lastSeen = activationDates?.[i]?.lastSeen;
  //   const activationDate = dev.activationTimes;
  //   const devData = { 
  //     ...dev, 
  //     ...onomondoData?.[uuid], 
  //   }
  //   return <DeviceTableRow dev={devData} currentId={currentId} selectSubPage={selectSubPage} onomondoKnown={onomondoDataIsSuccess} />   
  // })

  
  if(loginType !== "telegram"){
    return <>Log in with Telegram in order to see the diveces linked to your phone number.</>;
  }

  return (
    <>
      <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t('devices-in-use')}</Typography>
      <DeviceTable />
      <Stack 
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
        >
        <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t('devices-not-in-use')}</Typography>
        <ActivateDevicesDialog sx={{mt: 3}} />
      </Stack>
      <DeviceTable showDevicesNotInUse /> 


      {/* <div style={{display: "flex", alignItems: "center", clear:"both",  justifyContent: "space-between"}}>
        <Typography variant="h2" sx={{mt: 3, textAlign: "left"}}>{t('devices-not-in-use')}</Typography>
        <ActivateDevicesDialog sx={{mt: 3}} />
      </div>
      <TableContainer component={Paper} >
        <Table aria-label="collapsible table" size="small" sx={{ width: "99%", display: "table", tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "6%" }}/>
              <TableCell sx={{ width: "50%" }} align="left"><Box sx={{paddingLeft: "19px"}}></Box></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">{t('map')}</TableCell>
            </TableRow>
          </TableHead> 
          <TableBody>
          { deviceNotInUseTableRows }
          </TableBody>
        </Table>
      </TableContainer> */}
    </>
  )
}
const SubPageDevices = withTranslation()(PureSubPageDevices);

export default SubPageDevices;